import _ from 'lodash';
import React, { Component } from 'react';
import { Loader, Container, Dropdown, Button } from 'semantic-ui-react';
import { Table, Form, Alert, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    getLboxs,
    onUsernameChanged,
    onAccountChanged,
    onPasswordChanged,
    getPingsLboxs,
    getPinQueue,
    sendPingLbox
} from '../redux/actions';
import config from '../config/index'


class PinConfig extends Component {
    state = {
        loading: false,
        selectedVehicles: [],
        pin: null,
        pinConfirmation: null,
        showModal: false,
        refreshing:false,
        sending:false,
        vehicles: []

    }
    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    async componentWillMount() {
        let token = await sessionStorage.getItem('token');
        if (!token) {
            alert('Debes de iniciar sesion')
            this.props.history.push('/#')
        }
        else {
            let user = JSON.parse(token)
            this.props.onUsernameChanged(user.username);
            this.props.onAccountChanged(user.account);
            this.props.onPasswordChanged(user.password);
            this.getLboxPings();
            this.getPinQueue();
        }
    }
    componentWillUnmount(){
        this.getPinQueue(true);
    }
    async getLboxPings() {
        let aux = [];
        this.setState({refreshing: true})
        const { account, username, password, getPingsLboxs } = this.props;
        await getPingsLboxs(account, username, password);
        await getLboxs(account, username, password);
        this.setState({refreshing: false,vehicles:this.props.pingsLboxs})
    }
    addVehicles(objectno) {
        if (objectno === "todos") {

            if (this.state.selectedVehicles.length === this.props.pingsLboxs.length) {
                this.setState({ selectedVehicles: [] });
            }
            else {
                this.setState({ selectedVehicles: this.props.pingsLboxs })
            }
        }
        else {
            let lbox = _.find(this.props.pingsLboxs, { 'objectno': objectno });
            let index = _.findIndex(this.state.selectedVehicles, lbox);
            let aux = this.state.selectedVehicles;
            if (index >= 0) {
                aux.pop(lbox);
            }
            else {
                aux.push(lbox);
            }
            this.setState({ selectedVehicles: aux })
        }

    }
    async sendPing() {
      
        
        const { account, password, username } = this.props
        if (this.state.pin.length !== 3) {
            alert('El código de desbloqueo debe ser de tres dígitos.')
        } else if (Number(this.state.pin) !== Number(this.state.pinConfirmation)) {
            alert('Los códigos no coinciden.');
        }
        else if (this.state.selectedVehicles.length === 0) {
            alert('Debe de seleccionar por lo menos un vehículo.');

        }
        else {
            this.setState({sending:true});
            this.state.selectedVehicles.map(
                async vehicle => {
                    await this.props.sendPingLbox(account,username,password,vehicle.deviceid,vehicle.objectno,this.state.pin);
                    await this.sleep(1000);
                    if (this.props.message){
                        alert(this.props.message);
                    }
                   
                }
            )
            this.setState({sending:false});
            this.setState({pin:"", pinConfirmation:"", selectedVehicles:""});
        }
        

    }
    renderVehicles() {
        if (this.state.vehicles.length > 0) {
            return this.state.vehicles.map(
                vehicle => {
                    let formatDate =  vehicle.id ? moment(vehicle.id, 'YYYYMMDDHHmmss').format('DD-MM-YYYY HH:mm:ss') : 'De fábrica';
                    let index = _.findIndex(this.state.selectedVehicles, vehicle);
                    return (
                        <tr>
                            <td style={{ width: 30 }}> <Form.Check type="checkbox" checked={index >= 0 ? true : false} onClick={() => this.addVehicles(vehicle.objectno)} /></td>
                            <td>{vehicle.objectno}</td>
                            <td>{vehicle.pin ? vehicle.pin : '123'}</td>
                            <td>{formatDate}</td>
                        </tr>
                    );
                })

        } else {
            return (
                <tr>
                    <td></td>
                    <td>Sin vehículos disponibles.</td>
                    <td></td>
                    <td></td>
                </tr>
            )
        }
    }
    renderVehiclesOnModal(){
        return this.props.pinQueue.map(
            item =>{
                
                return (
                    <div style={{display:'flex', width:'100%', justifyContent:'space-evenly'}}>
                    <span style={{width:'40%',fontSize:15}}>{item.objectno}</span>
                    <span style={{width:'20%',fontSize:15}}>{String(item.comando).substring(1,4)}</span>
                    <span style={{width:'30%',fontSize:15}}>{item.status}</span>
                </div>
                );

            }
        )
    }
    filterForText(text) {
        if (text === "") {
        
            this.setState({vehicles: this.props.pingsLboxs})
        }
        const newArray = this.props.pingsLboxs.filter(
            item => {
                const textData = String(text).toUpperCase();
                const vehicle = String(item.objectno).toUpperCase();

                if (vehicle.indexOf(textData) !== -1) {
                    return vehicle.indexOf(textData) > -1
                }
            }
        );

        if(newArray.length===0){
            this.setState({vehicles: this.props.pingsLboxs})
        }
        else{
            this.setState({vehicles: newArray});
        }
       
    };
    renderModal(){
        return(
            <Modal
              show={this.state.showModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={()=>this.setState({showModal:false})}

            >
            <Modal.Header>
                <h5>Lista de vehículos en espera</h5>
            </Modal.Header>
              <Modal.Body >
                <div style={{display:'flex',flexDirection:'column'}}>
                    <div style={{display:'flex', width:'100%', justifyContent:'space-evenly'}}>
                        <span style={{width:'40%', fontSize:16,fontWeight:'bold'}} >Vehículo</span>
                        <span style={{width:'20%' , fontSize:16,fontWeight:'bold' }}>Pin nuevo</span>
                        <span style={{width:'30%' , fontSize:16,fontWeight:'bold' }}>Estado</span>
                    </div>
                    {this.renderVehiclesOnModal()}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={()=>this.setState({showModal:false})}>Cerrar</Button>
              </Modal.Footer>
            </Modal>
        );
    }
    renderAlert() {
        return (
            <Alert variant='primary'>
                Cambio de codigo de desbloqueo en progreso.
                <Alert.Link onClick={()=> this.setState({showModal:true})}> Ver progreso. </Alert.Link>
            </Alert>
        );
    }
    async getPinQueue(flag){
            
            await this.props.getPinQueue(this.props.account,this.props.username, this.props.password );
          
            await  this.sleep(30000); 
            if (flag){
                
                
            }
            else{
                this.getPinQueue();
            }
            
    }
    render() {
        return (
            <div style={{ display: 'flex', width: '100%', backgroundColor: '#C9CAD9', height: '92.35%',flexDirection:'column' }}>
                {this.props.pinQueue.length>0? this.renderAlert(): <div/>}
                <div style={styles.container}>
                    <div style={styles.form}>
                        <Form>
                            <Form.Group>
                                <Form.Label style={{ fontSize: 24 }}>Pin de seguridad</Form.Label>
                                <Form.Text style={{ fontSize: 16,color: config.colors.strongGray, textAlign:'justify',width:'90%' }}>El pin de seguridad es un número de 3 dígitos (solo numéricos) el cual permitirá a desbloquear el vehículo manualmente.</Form.Text>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Ingrese el código nuevo.</Form.Label>
                                <Form.Control type="number" placeholder="Código nuevo" onChange={(e) => this.setState({ pin: e.target.value })} value={this.state.pin} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Confirme el código.</Form.Label>
                                <Form.Control type="number" placeholder="Código nuevo" onChange={(e) => this.setState({ pinConfirmation: e.target.value })} value={this.state.pinConfirmation} />
                            </Form.Group>
                            <Button style={{ backgroundColor: config.colors.green, color: 'white' }} fluid onClick={() => this.sendPing()} loading={this.state.sending}>Enviar Código</Button>
                        </Form>
                    </div>
                    <div style={styles.table}>
                        <div style={styles.searchbar}>
                            <Form style={{ width: '100%' }} inline>
                                <Form.Control style={{ width: '30%' }} placeholder='Buscar vehículo' onChange={(e) => this.filterForText(e.target.value)} />
                                <Button style={{ backgroundColor: config.colors.green, color: 'white', marginLeft: '52%', width: '15%' }} onClick={()=>this.getLboxPings()} loading={this.state.refreshing} >
                                    <img style={{width:12,height:12,marginRigth: 5}} src={`http://168.235.83.246/images/refresh.png`} />
                                    Actualizar
                                </Button>
                            </Form>
                        </div>
                        <div style={{ display: 'flex', height: '92%', width: '100%' }}>
                            <Table striped hover responsive bordered hover>
                                <thead style={{ backgroundColor: '#E2DDDD' }}>

                                    <th style={{ width: 30 }}> <Form.Check type="checkbox" onClick={() => this.addVehicles('todos')} checked={this.state.selectedVehicles.length === this.props.pingsLboxs.length ? true : false} /></th>
                                    <th>Vehículo</th>
                                    <th>Pin actual</th>
                                    <th>Fecha de modificación</th>

                                </thead>
                                <tbody>{this.renderVehicles()}</tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                {this.renderModal()}
            </div>
        )

    }

}

const styles = {
    loader: {
        marginTop: 100
    },
    container: {
        display: 'flex',
        backgroundColor: '#C9CAD9',
        height: '96.35%',
        
    },
    form: {
        padding: 20,
        margin: 10,
        width: '20%',
        backgroundColor: 'white'
    },
    table: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        margin: 10,
        width: '80%',
        backgroundColor: 'white',

    },
    searchbar: {
        widthflex: 1,
        flexDirection: 'row',
        display: 'flex',
        height: 60,
        padding: 10,
        backgroundColor: config.colors.strongGray,
        width: '100%'
    }
}

const mapStateToProps = state => {
    return {
        account: state.auth.account,
        password: state.auth.password,
        username: state.auth.username,
        lboxs: state.lbox.lboxs,
        message: state.lbox.message,
        history: state.lbox.history,
        pingsLboxs: state.lbox.lboxsPings,
        pinQueue: state.lbox.pinQueue,
        result: state.lbox.result
    };
}
export default connect(mapStateToProps, {
    getLboxs,
    onUsernameChanged,
    onAccountChanged,
    onPasswordChanged,
    getPingsLboxs,
    getLboxs,
    getPinQueue,
    sendPingLbox
})(PinConfig);