import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Button } from 'semantic-ui-react';
import {
    onUsernameChanged,
    onAccountChanged,
    onPasswordChanged,
    getAviableLbox,
    getLinks,
    linkLBox
} from '../redux/actions';
import config from '../config/index'
class InstallProcess extends Component {
    state ={
        selectedLbox: null,
        selectedLink: null,
        loading: false,
        linking:false
    }
    async componentWillMount() {
        let token = await sessionStorage.getItem('token');
        if (!token) {
            alert('Debes de iniciar sesion')
            this.props.history.push('/#')
        }
        else {
            let user = JSON.parse(token)
            this.props.onUsernameChanged(user.username);
            this.props.onAccountChanged(user.account);
            this.props.onPasswordChanged(user.password);
        }
        const { account, username, password, getLinks, getAviableLbox } = this.props;
        await  getLinks( account, username, password);
        await getAviableLbox( account,username, password);
        if(this.props.message){
            alert(this.props.message);
        }
        
    }
    async onClickButton(){
        const { account, username, password, linkLBox } = this.props;
        this.setState({linking: true});
        
        await linkLBox(account, username, password,this.state.selectedLink.objectno,this.state.selectedLbox.friendlyName, this.state.selectedLink.objectuid);
        this.setState({linking:false});
        alert(this.props.message);
        
    }
    render() {
        let { selectedLbox,selectedLink}=this.state;
        return (
            <div style={styles.body}>
                <div style={styles.container}>
                    <div style={{display:'flex', width:'100%', justifyContent:'center'}}>
                        <h2>Instalación de LBox®</h2>
                    </div>
                    <div style={{display:'flex', width:'90%', justifyContent:'center',marginTop:15}} >
                        <span style={{fontSize:22, textAlign:'justify'}}>
                            Bienvenido al proceso de instalación de un LBox, es importante completar 
                            cada uno de los siguientes pasos para finalizar el proceso.
                        </span>
                    </div>
                    <div style={{display:'flex', width:'80%', justifyContent:'space-between',marginTop:15}} >
                       <div style={{display:'flex', flexDirection:'column', width:'30%'}}>
                           <span style={{fontSize:17, marginTop:10,marginBottom:10}}>Seleccione un LBox®:</span>
                           <Dropdown 
                                selection
                                search
                                options={this.props.aviableLbox}
                                onChange= {(e,data) => this.setState({selectedLbox: data.value})}
                            />
                            <span style={{fontSize:17, marginTop:10,marginBottom:10}}>Seleccione un Link:</span>
                           <Dropdown 
                                selection
                                search
                                options={this.props.links}
                                onChange= {(e,data) => this.setState({selectedLink: data.value})}
                            />
                       </div>
                       <div style={{display:'flex', flexDirection:'column', width:'30%'}}>
                           <span style={{fontSize:17, marginTop:10,marginBottom:10}} >Datos generales:</span>
                           <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'space-between',marginTop:10,marginBottom:10}}>
                               <span style={{width:'40%', color:'lightgray'}}> Objectno:</span>
                               <span style={{width:'50%'}}>{selectedLink? selectedLink.objectno : 'Sin seleccionar' }</span>
                           </div>
                           <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'space-between',marginTop:10,marginBottom:10}}>
                               <span style={{width:'40%', color:'lightgray'}}> Device id:</span>
                               <span style={{width:'50%'}}>{selectedLbox? selectedLbox.deviceid : 'Sin seleccionar' }</span>
                           </div>
                           <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent:'space-between',marginTop:10,marginBottom:10}}>
                               <span style={{width:'40%', color:'lightgray'}}> Friendly name:</span>
                               <span style={{width:'50%'}}>{selectedLbox? selectedLbox.friendlyName : 'Sin seleccionar' }</span>
                           </div>
                       </div>
                    </div>
                    <Button onClick={()=> this.onClickButton()} loading={this.state.linking} disabled={selectedLbox && selectedLink? false: true } style={{backgroundColor: config.colors.green, color:'white', width:300,marginTop:50}} >Vincular</Button>
                </div>
            </div>
        );
    }
}
const styles = {
    loader: {
        marginTop: 100
    },
    body: {
        flex: 1,
        flexDirection: 'row',
        display: 'flex',
        height: '92%',
        backgroundColor: config.colors.lightGray, 
        padding: 40
    },
    container: {
        padding: 20,
        width: '100%',
        flex:1,
        backgroundColor: 'white',
        flexDirection:'column',
        alignItems:'center',
        display:'flex'
    }

}


const mapStateToProps = state => {
    return {
        account: state.auth.account,
        password: state.auth.password,
        username: state.auth.username,
        links : state.lbox.links,
        aviableLbox: state.lbox.aviableLbox,
        message:state.lbox.message
    };
}
export default connect(mapStateToProps, {
    onUsernameChanged,
    onAccountChanged,
    onPasswordChanged,
    getAviableLbox,
    getLinks,
    linkLBox
})(InstallProcess);