import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Placeholder,Container,Loader } from 'semantic-ui-react';
import { Modal, Button,Table } from 'react-bootstrap';
import {
    getLboxs,
    onUsernameChanged,
    onAccountChanged,
    onPasswordChanged,
    getInactiveLboxs,
    getSecureVehiclesByDate,
    getAlarmsByDate,
    getAlarmsByVehicle,
    getInSecureVehiclesByDate
} from '../redux/actions';
import { BarChart, XAxis, YAxis, Tooltip, Legend, Bar, PieChart, Pie, Cell, Label } from 'recharts';
import moment from 'moment';
import config from '../config';


class Dashboard extends Component {
    state = {
        loading: true,
        loadingAlarms:false,
        totalLbox: 0,
        liberados: 0,
        alarmados: 0,
        armados: 0,
        inactivos: 0,
        alarmadosHoy: 0,
        alarmadosSemana: 0,
        alarmadosMesTotal: 0,
        liberadosHoyTotal: 0,
        liberadosMesTotal: 0,
        alarmadosMes: [],
        liberadosHoy: [],
        liberadosMes: [],
        segurosHoy: [],
        segurosSemana: [],
        segurosMes: [],
        infoSelected: {
            title: "",
            data: []
        },
        infoAlarmSelected:{
            title: "",
            data: []
        },
        selectedAlarm: null,
        dateSelected: 'today',
        showModalInsecure: false,
        showModalAlarms:false,
        liberadosSemana: 0,
        data: [
            {
                "name": "Alarmados",
                "total": 25,
            },
            {
                "name": "Liberados",
                "total": 10
            },
            {
                "name": "Armados",
                "total": 5
            }

        ],
        data01: [
            {
                "name": "Inactivos",
                "value": 100
            },
            {
                "name": "Total",
                "value": 400
            }]
    }
    async componentWillMount() {
        let token = await sessionStorage.getItem('token');
        let todayStart = moment().format('YYYY-MM-DD 00:00:00');
        let todayEnd = moment().format('YYYY-MM-DD 23:59:59');
        let startDateUtc = moment(todayStart, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
        let endDateUtc = moment(todayEnd, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
        
        if (!token) {

            alert('Debes de iniciar sesion')
            this.props.history.push('/#')

        }
        else {


            let user = JSON.parse(token)
            this.props.onUsernameChanged(user.username);
            this.props.onAccountChanged(user.account);
            this.props.onPasswordChanged(user.password);
            const { account, username, password } = this.props;
            
            const calls = [
                this.props.getLboxs(account, username, password),
                this.props.getInactiveLboxs(account, username, password),
                this.props.getAlarmsByDate(account, username, password, startDateUtc, endDateUtc),
                this.props.getInSecureVehiclesByDate(account, username, password, startDateUtc, endDateUtc),

            ]
            await Promise.all(calls);
            let liberados = _.countBy(this.props.lboxs, { modo: 'Liberado' });
            let armados = _.countBy(this.props.lboxs, { modo: 'Armado' });
            let alarmados = _.countBy(this.props.lboxs, { modo: 'Alarmado' });
            //Obtener los alarmados de hoy
            let alarmadosHoyTotal = 0;
            for( let i=0; i<this.props.alarmsByDate.length; i++){
                alarmadosHoyTotal+= Number(this.props.alarmsByDate[i].alarms);
            }
            let alarmadosHoy = this.props.alarmsByDate;
            
            //Obtener los liberados de hoy
            
            let segurosHoy = this.filterSecure(this.props.inSecureByDate, this.props.lboxs);
            //Obtener los seguros de hoy
            let liberadosHoy = this.getDifferences(this.props.lboxs,segurosHoy);
            console.log(liberadosHoy);

            let aux = [
                {
                    "name": "Alarmados",
                    "total": alarmados.true ? alarmados.true : 0
                },
                {
                    "name": "Armados",
                    "total": armados.true ? armados.true : 0
                },
                {
                    "name": "Liberados",
                    "total": liberados.true ? liberados.true : 0
                },
                {
                    "name": "Inactivos",
                    "total": this.props.inactiveLboxs
                }
            ];
            let aux1 = [
                {
                    "name": "Inactivos",
                    "value": this.props.inactiveLboxs
                },
                {
                    "name": "Activos",
                    "value": this.props.lboxs.length - this.props.inactiveLboxs
                }
            ]
           
        
            
            this.setState({
                totalLbox: this.props.lboxs.length,
                data: aux,
                data01: aux1,
                alarmadosHoyTotal: alarmadosHoyTotal,
                alarmadosHoy: _.orderBy(alarmadosHoy,'alarms','desc'),
                liberadosHoy: liberadosHoy,
                segurosHoy: segurosHoy,
                loading: false
            })

        }

    }

    filterSecure(insecures, lboxs){
        const lboxNames = lboxs.map(l => l.objectno);
        const aux = insecures.filter( insecure => lboxNames.includes(insecure.objectno) && insecure.estado === 'Armado' );
        const aux_sorted = _.orderBy(aux,'fecha','desc');
        const aux_unique = _.uniqBy(aux_sorted, 'objectno');
        return aux_unique;
    }
    getDifferences(arrayA , arrayB){
        let array1 =[];
        for(let i=0; i<arrayA.length;i++){
            if(arrayA[i].modo !== null){
                array1.push(arrayA[i]);
            }
        }
        let aux= _.differenceBy(array1,arrayB, 'objectno');
        
        return aux;
    }
    async renderBars() {
        if (this.props.lboxs.length > 0) {

        }
    }
    renderLoadingLines() {
        return (
            <Placeholder >
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
            </Placeholder>
        );
    }
    renderLoadingImage() {
        return (
            <Placeholder style={{ height: '80%', width: '90%' }}>
                <Placeholder.Image />
            </Placeholder>
        );
    }

    renderRows() {
        if (this.state.infoSelected.data.length > 0) {
            return this.state.infoSelected.data.map(

                lbox => {
                    
                    let fechaUtc= moment(lbox.fecha, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY HH:mm:ss UTC');
                    let timez = new Date(fechaUtc).toString();
                    

                    return (

                        <tr>
                            <td>
                                {lbox.objectno}
                            </td>
                            <td>
                                {lbox.friendlyname || lbox.serieLBOX}
                            </td>
                            <td>
                                {lbox.estado || lbox.modo}
                            </td>
                            <td>
                                {lbox.fecha ? moment(timez).format('DD-MM-YYYY HH:mm'): "Sin reporte"}
                            </td>

                        </tr>
                    );
                }
            );
        }
        return (
            <tr>Sin LBox</tr>
        );
    }
    renderAlarms() {
        
        if (this.state.infoAlarmSelected.data.length > 0) {
            return this.state.infoAlarmSelected.data.map(

                lbox => {
                
                    return (

                        <tr onClick={()=> this.searchAlarms(lbox.objectno)}>
                            <td>
                                {lbox.objectno}
                            </td>
                            <td>
                                {lbox.alarms}
                            </td>
                        </tr>
                    );
                }
            );
        }
        return (
            <tr>Sin LBox</tr>
        );
    }
    async searchAlarms(objectno){
        this.setState({selectedAlarm: objectno,loadingAlarms:true })
        let { account, password, username, getAlarmsByVehicle } = this.props;
        let startDate = moment().format('YYYY-MM-DD 00:00:00');
        let endDate = moment().format('YYYY-MM-DD 23:59:59');

        switch(this.state.dateSelected){
            case 'today': 
                startDate = moment().format('YYYY-MM-DD 00:00:00');
                endDate = moment().format('YYYY-MM-DD 23:59:59');
                break;
            default:
                startDate = moment().format('YYYY-MM-DD 00:00:00');
                endDate = moment().format('YYYY-MM-DD 23:59:59');
                break;
        }
        let startDateUtc= moment(startDate, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
        let endDateUtc= moment(endDate, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
        
        await getAlarmsByVehicle(account,username,password,objectno,startDateUtc,endDateUtc);
        this.setState({loadingAlarms:false});
    }
    renderAlarmsByVehicle(){
        if(this.state.loadingAlarms){
            return (
                <Container style={{width:'100%',display:'flex',justifyContent:'center'}}>
                <Loader size='big' active inline='centered'  >Cargando Alarmas...</Loader>
                </Container>
            );
        }
        if (this.props.alarmsByVehicle.length > 0) {
            return this.props.alarmsByVehicle.map(

                lbox => {
                    let fechaUtc= moment(lbox.fecha, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY HH:mm:ss UTC');
                    let timez = new Date(fechaUtc).toString();
                    return (

                        <tr  >
                            <td>
                                {moment(timez).format('DD-MM-YYYY HH:mm:ss')}
                            </td>
                            <td>
                                {lbox.coordenadas}
                            </td>
                        </tr>
                    );
                }
            );
        }
        return (
            <tr>No se encontraron alarmas</tr>
        );
    }
    renderModalAlarms() {
        if(this.state.selectedAlarm){
            return (
                <Modal
                    show={this.state.showModalAlarms}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => this.setState({ showModalAlarms: false,selectedAlarm:null })}
                    
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {this.state.selectedAlarm}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{maxHeight:'80%'}}>
                        <div style={{ display: 'flex',maxHeight:600 , width: '100%' }}>
                            <Table striped bordered responsive hover style={{ boxShadow: '1px 3px 1px #9E9E9E', width: '100%'}}>
                                <thead style={{ backgroundColor: '#E2DDDD',position:'sticky',top:0 }}>
                                    <th style={{position:'sticky',top:0,backgroundColor: '#E2DDDD'}}>
                                       Fecha
                                    </th>
                                    <th style={{position:'sticky',top:0,backgroundColor: '#E2DDDD'}}>
                                       Ubicación
                                    </th>
                                </thead>
                                <tbody style={{ backgroundColor: 'white' }}>
                                    {this.renderAlarmsByVehicle()}
                                </tbody>
                            </Table>
    
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={() => this.setState({ selectedAlarm: null })}>Atras</Button>
                        <Button onClick={() => this.setState({ showModalAlarms: false,selectedAlarm:null })}>Cerrar</Button>
                    </Modal.Footer>
                </Modal>
            );
        }
        return (
            <Modal
                show={this.state.showModalAlarms}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => this.setState({ showModalAlarms: false })}
                
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.state.infoSelected.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{maxHeight:'80%'}}>
                    <div style={{ display: 'flex',maxHeight:600 , width: '100%' }}>
                        <Table striped bordered responsive hover style={{ boxShadow: '1px 3px 1px #9E9E9E', width: '100%'}}>
                            <thead style={{ backgroundColor: '#E2DDDD',position:'sticky',top:0 }}>
                                <th style={{position:'sticky',top:0,backgroundColor: '#E2DDDD'}}>
                                    Vehículo
                                </th>
                                <th style={{position:'sticky',top:0,backgroundColor: '#E2DDDD'}}>
                                    Número de alarmas
                                </th>
                            </thead>
                            <tbody style={{ backgroundColor: 'white' }}>
                                {this.renderAlarms()}
                            </tbody>
                        </Table>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.setState({ showModalAlarms: false })}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        );

    }
    renderModalInsecure() {
        return (
            <Modal
                show={this.state.showModalInsecure}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => this.setState({ showModalInsecure: false })}
                
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {this.state.infoSelected.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{maxHeight:'80%'}}>
                    <div style={{ display: 'flex',maxHeight:600 , width: '100%' }}>
                        <Table striped bordered responsive hover style={{ boxShadow: '1px 3px 1px #9E9E9E', width: '100%'}}>
                            <thead style={{ backgroundColor: '#E2DDDD',position:'sticky',top:0 }}>
                                <th style={{position:'sticky',top:0,backgroundColor: '#E2DDDD'}}>
                                    Vehículo
                                </th>
                                <th style={{position:'sticky',top:0,backgroundColor: '#E2DDDD'}}>
                                    LBox
                                </th>
                                <th style={{position:'sticky',top:0,backgroundColor: '#E2DDDD'}} >
                                    Estado
                                </th>
                                <th style={{position:'sticky',top:0,backgroundColor: '#E2DDDD'}}>
                                    { this.state.infoSelected.secure ? 'Última fecha de modo seguro' : 'Ultimo reporte'}
                                </th>
                            </thead>
                            <tbody style={{ backgroundColor: 'white' }}>
                                {this.renderRows()}
                            </tbody>
                        </Table>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.setState({ showModalInsecure: false })}>Cerrar</Button>
                </Modal.Footer>
            </Modal>
        );

    }
    render() {
        return (
            <div style={styles.container}>
                <div style={styles.graphicsContainer}>
                    <div style={styles.pieContainer}>
                        <span style={styles.pieTitleContainer}>Dispositivos activos</span>
                        {
                            this.state.loading ? this.renderLoadingImage()
                                :
                                <PieChart width={400} height={220}>
                                    <Tooltip />
                                    <Legend />
                                    <Pie data={this.state.data01} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80} innerRadius={60} fill="#9bb800">
                                        <Cell fill={config.colors.lightGray} />
                                        <Cell fill={config.colors.green} />
                                        <Label value={( Number(this.state.totalLbox) - Number(this.props.inactiveLboxs)) + "/" + this.state.totalLbox} position="center" fontSize={30} color={"#99B800"} />
                                    </Pie>
                                </PieChart>}
                    </div>
                    <div style={styles.barContainer}>
                        <div style={styles.barTitleContainer}>
                            <span style={styles.grayTitleText}>Total de dispositivos:</span>
                            <span style={{ fontSize: 40, marginRight: 15, color: config.colors.green }}>{this.state.totalLbox}</span>
                        </div>
                        {
                            this.state.loading ? this.renderLoadingImage()
                                :
                                <BarChart width={800} height={220} data={this.state.data}>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="total" fill="#9bb800" label >
                                    </Bar>
                                </BarChart>
                        }
                    </div>
                </div>
                <div style={styles.bottomContainer}>
                    <div style={{ display: 'flex', height: '100%', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', width: '100%', height: '30%', flexDirection: 'column', alignItems: 'center', padding: 10, justifyContent: 'center', marginTop: 30 }}>
                            <span style={styles.insideContainerSubtext}> Alarmas activadas: </span>
                        </div>
                        <div style={{ display: 'flex', width: '100%', height: '30%', flexDirection: 'column', alignItems: 'center', padding: 10, justifyContent: 'center' }}>
                            <span style={styles.insideContainerSubtext}> Dispositivos inseguros: </span>
                        </div>
                        <div style={{ display: 'flex', width: '100%', height: '30%', flexDirection: 'column', alignItems: 'center', padding: 10, justifyContent: 'center' }}>
                            <span style={styles.insideContainerSubtext}> Dispositivos seguros: </span>
                        </div>

                    </div>

                    <div style={styles.bottomHorizontalContainer}>
                        <span style={styles.subTitleText}>Hoy</span>
                        {
                            this.state.loading ? this.renderLoadingLines()
                                :
                                <div onClick={() => this.setState({ infoAlarmSelected: { title: "Unidades alarmadas (Hoy)", data:this.state.alarmadosHoy }, showModalAlarms: true,dateSelected:'today' })}  style={styles.insideContainer}>
                                    <span style={styles.insideContainerNumber}>{this.state.alarmadosHoyTotal}</span>
                                </div>
                        }
                        {
                            this.state.loading ? this.renderLoadingLines()
                                :
                                <div onClick={() => this.setState({ infoSelected: { title: "Dispositivos inseguros (Hoy)", data:this.state.liberadosHoy, secure: false }, showModalInsecure: true })} style={styles.insideContainer}>
                                    <span style={styles.insideContainerNumber}>{this.state.liberadosHoy.length}</span>
                                </div>
                        }
                        {
                            this.state.loading ? this.renderLoadingLines()
                                :
                                <div onClick={() => this.setState({ infoSelected: { title: "Dispositivos Seguros (Hoy)", data:this.state.segurosHoy, secure: true }, showModalInsecure: true })} style={styles.insideContainer}>
                                    <span style={styles.insideContainerNumber}>{this.state.segurosHoy.length}</span>
                                </div>
                        }
                    </div>
                </div>
                {this.renderModalInsecure()}
                {this.renderModalAlarms()}
            </div>
        );
    }
}
const styles = {
    container: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        height: '92%',
        backgroundColor: config.colors.lightGray,
        padding: 20,
        justifyContent: 'space-evenly'
    },
    barContainer: {
        display: 'flex',
        width: '65%',
        backgroundColor: 'white',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center',
        boxShadow: '1px 3px 5px #9E9E9E ',
        alignItems: 'center'
    },
    barTitleContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
        flexDirection: 'row',
        alignItems: 'flex-end'
    },
    pieContainer: {
        display: 'flex',
        width: '30%',
        backgroundColor: 'white',
        height: '100%',
        flexDirection: 'column',
        boxShadow: '1px 3px 5px #9E9E9E ',
        justifyContent: 'center',
        alignItems: 'center'
    },
    pieTitleContainer: {
        fontSize: 24,
        width: '100%',
        textAlign: 'center'
    },
    graphicsContainer: {
        display: 'flex',
        width: '100%',
        height: '45%',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    grayTitleText: {
        color: config.colors.strongGray,
        fontSize: 16,
        marginRight: 10,
        display: 'flex',
        height: '100%',
        alignItems: 'center'
    },
    bottomContainer: {
        display: 'flex',
        width: '100%',
        height: '45%',
        flexDirection: 'row',
    },
    bottomHorizontalContainer: {
        display: 'flex',
        width: '20%',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',

    },
    subTitleText: {
        fontSize: 25
    },
    insideContainer: {
        display: 'flex',
        width: '100%',
        backgroundColor: 'white',
        height: '25%',
        flexDirection: 'column',
        boxShadow: '1px 3px 5px #9E9E9E ',
        alignItems: 'center',
        padding: 10,
        justifyContent: 'center'
    },
    insideContainerSubtext: {
        fontSize: 24,
        color: config.colors.strongGray,
        marginRight: 15,
        width: '100%',
        textAlign: 'center'
    },
    insideContainerNumber: {
        fontSize: 37,
        color: config.colors.green
    }

};
const mapStateToProps = state => {
    return {
        account: state.auth.account,
        password: state.auth.password,
        username: state.auth.username,
        lboxs: state.lbox.lboxs,
        message: state.lbox.message,
        inactiveLboxs: state.lbox.inactiveLboxs,
        alarmsByDate: state.lbox.alarmsByDate,
        secureByDate: state.lbox.secureByDate,
        inSecureByDate: state.lbox.inSecureByDate,
        alarmsByVehicle: state.lbox.alarmsByVehicle
    };
}
export default connect(mapStateToProps, {
    getLboxs,
    onUsernameChanged,
    onAccountChanged,
    onPasswordChanged,
    getInactiveLboxs,
    getSecureVehiclesByDate,
    getAlarmsByDate,
    getAlarmsByVehicle,
    getInSecureVehiclesByDate
})(Dashboard);