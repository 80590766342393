import React, { Component } from 'react';
import {
    Form,
    Spinner,
    Button,
    FormControl
} from 'react-bootstrap';
import { connect } from 'react-redux';
import config from '../config';
import "../App.css";
import {
    onAccountChanged,
    onPasswordChanged,
    onUsernameChanged,
    doLogin
} from '../redux/actions';

class Login extends Component {
    state = {
        waiting: false
    }
    async onButtonPress() {

        const { username, password, account, doLogin } = this.props;
        if (username === "" || username === null) {
            alert('Ingrese un usuario')
        }
        else if (password === "" || password === null) {
            alert('Ingrese una contraseña')
        }
        else {
            this.setState({ waiting: true })
            await doLogin(account, username, password)
            this.setState({ waiting: false })
            if (this.props.message === null) {
                
                if (this.props.account === null || this.props.account === "") {
                    alert("Error, vuelva a intentarlo")
                }
                else {
                    this.props.history.push('/application/dashboard')
                }



            }
            else {
                alert(this.props.message);
            }
        }
    }
    renderButton() {
        if (this.state.waiting) {
            return (
                <Spinner
                    variant="primary"
                    style={{ alignSelf: 'center' }}
                    animation="border"
                    role="status">
                </Spinner>
            );
        }
        return (
            <Button class="btn" onClick={() => this.onButtonPress()} style={{ backgroundColor: config.colors.green, border: '0px', width: '50%' }} type="submit">
                Ingresar
            </Button>
        );

    }
    render() {
        return (
            <div style={styles.body}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '80%', paddingBottom: 15, paddingTop: 15, justifyContent: 'center', alignItems: 'center' }}>
                    <div style={styles.leftBackgroundContainer}>
                        <span style={styles.titleText}>¿Que es LBox®?</span>
                        <span style={styles.contentText}>
                            Lbox es una solución tecnológica desarrollada por  Inroute
                            totalmente compatible e integrada a TomTom telematics, que permiten
                            establecer un modelo de segurida inteligente dentro del vehículo para
                            aplicación de protocolos de seguridad desde el propio vehículo de forma automática.
                        </span>
                    </div>
                    <div style={styles.formContainer}>
                        <div style={styles.form}>
                            <Form style={{ width: '90%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }} >
                                <img src={'http://168.235.83.246/images/LBox_logo.png'} style={styles.lboxLogo} />
                                <span style={styles.loginText}>Inicia sesión</span>
                                <Form.Group controlId="formBasicEmail" style={styles.inputBox}>
                                    <Form.Label>Cuenta:</Form.Label>
                                    <Form.Control value={this.props.account} placeholder="Ingrese su cuenta " onChange={(e) => this.props.onAccountChanged(e.target.value)} />
                                </Form.Group>
                                <Form.Group controlId="formBasicEmail" style={styles.inputBox}>
                                    <Form.Label>Usuario:</Form.Label>
                                    <Form.Control value={this.props.username} placeholder="Ingrese su usuario" onChange={(e) => this.props.onUsernameChanged(e.target.value)} />
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword" style={styles.inputBox} >
                                    <Form.Label>Contraseña:</Form.Label>
                                    <Form.Control value={this.props.password} type="password" onChange={(e) => this.props.onPasswordChanged(e.target.value)} />
                                </Form.Group>
                                {this.renderButton()}
                                <div style={styles.poweredContainer} >
                                    <span style={styles.poweredText}>Powered by</span>
                                    <img src={'http://168.235.83.246/images/logo.png'} style={styles.inrouteLogo} />
                                </div>
                            </Form>

                        </div>

                    </div>
                    <div style={styles.rightBackgroundContainer}></div>


                </div>
            </div>
        );
    }
}

const styles = {
    lboxLogo: {
        width: '50%',
        marginBottom: 15
    },
    body: {
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
        margin: '0',
        marginBottom: 0,
        padding: 0,
        backgroundColor: config.colors.lightGray,
        background: `url(http://168.235.83.246/images/fondo.png)  center center fixed`,
        backgroundSize: 'cover',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    form: {
        display: 'flex',
        width: '100%',
        alignSelf: 'flex-end',
        backgroundColor: config.colors.white,
        padding: 15,
        justifyContent: 'center'
    },
    titleText: {
        color:'white',
        fontSize:23,
        fontWeight:'bold',
        marginBottom:20
    },
    contentText: {
        color:'white',
        fontSize:18,
        textAlign:'justify',
        width:'90%'
    },
    inputBox: {
        width: '80%'
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        width: '40%',
        minHeight: 300
    },
    headerStyle: {
        display: 'flex',
        width: '100%',
        backgroundColor: config.colors.strongGray,
        flexDirection: 'row',
        padding: 10,
        paddingLeft: 20,
        maxHeight: 60
    },
    poweredContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 20
    },
    rightBackgroundContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '10%',
        backgroundColor: 'rgba(56,56,56,0.79)',
        height: 400
    },
    leftBackgroundContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '40%',
        backgroundColor: 'rgba(56,56,56,0.79)',
        height: 400,
        alignItems:'center',
        paddingTop:30
    },
    inrouteLogo: {
        width: '20%',
        display: 'flex',
        alignSelf: 'flex-end'
    },
    poweredText: {
        color: '#707070',
        fontSize: 14,
        marginRight: 15
    },
    loginText: {
        color: '#707070',
        fontSize: 18
    }
}
const mapStateToProps = state => {
    return {
        account: state.auth.account,
        password: state.auth.password,
        username: state.auth.username,
        message: state.auth.message
    };
}
export default connect(mapStateToProps, {
    doLogin,
    onAccountChanged,
    onPasswordChanged,
    onUsernameChanged,
})(Login);