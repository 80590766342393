import _, { get } from "lodash";
import React, { Component } from "react";
import { Loader, Container, Button, Popup, Progress } from "semantic-ui-react";
import { Table, Form, Modal, Alert } from "react-bootstrap";
import config from "../config/index";
import GoogleMapReact from "google-map-react";
import { connect } from "react-redux";
import moment from "moment";
import "../css/area.css";
import {
  getLboxs,
  onUsernameChanged,
  onAccountChanged,
  onPasswordChanged,
  getAreaQueue,
  getAreasWebfleet,
  getCoordinates,
  sendArea,
  resendArea,
  deletePedingArea,
  deletePedingMultipleAreas,
  getAreasLbox,
} from "../redux/actions";

const BLOCK_STATUS = ["Por confirmar", "Por confirmar borrada"];

const AnyReactComponent = ({ name, radio, tipo }) => (
  <Popup
    content={
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>Nombre: {name}</span>
        <span>Radio: {radio} m</span>
      </div>
    }
    trigger={
      tipo === 1 ? (
        <img
          style={{ width: 45, height: 45 }}
          src={"http://168.235.83.246/images/pinGeocercaSegura.png"}
        />
      ) : (
        <img
          style={{ width: 45, height: 45 }}
          src={"http://168.235.83.246/images/pinGeocercaInsegura.png"}
        />
      )
    }
  />
);

class Area extends Component {
  state = {
    areas: [],
    center: {
      lat: 24.8042,
      lng: -107.431,
    },
    zoom: 5,
    loading: true,
    areasTypes: [],
    selectedAreas: [],
    showModal: false,
    selectedVehicles: [],
    showModalProgress: false,
    resendingArea: false,
    showReSendProgressModal: false,
    showDeleteProgressModal: false,
    sendingAreas: false,
    installer: false,
    erasing: null,
    vehicleAvailable: false,
    openModalWithProgresBar: false,
    progresBarInitialValue: 0,
    actualVehicleInProgresBar: "",
    invalidVehiclesWithPlants: [],
    notUpdatedVehiclesModal: false,
    auxLboxArray: [],
    lboxAux: [],
  };
  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  async componentWillMount() {
    let token = await sessionStorage.getItem("token");
    if (!token) {
      alert("Debes de iniciar sesion");
      this.props.history.push("/#");
    } else {
      let user = JSON.parse(token);
      this.props.onUsernameChanged(user.username);
      this.props.onAccountChanged(user.account);
      this.props.onPasswordChanged(user.password);
      const { account, username, password, getLboxs } = this.props;

      await getLboxs(account, username, password);
      await this.props.getAreasWebfleet(account, username, password);
      let aux = [];
      this.getAreaQueue();
      if (this.props.areasWebfleet.length > 0) {
        this.props.areasWebfleet.map((area) => {
          aux.push({
            areano: area.areano,
            tipo: 1,
            tipoG: area.tipo,
          });
        });
      }
      this.setState({
        areas: this.props.areasWebfleet,
        areasTypes: aux,
        installer: user.installer,
      });
      this.setState({ lboxAux: this.props.lboxs });
      this.setState({ loading: false });
    }
  }
  componentWillUnmount() {
    this.getAreaQueue(true);
  }
  renderLoader() {
    return (
      <Container style={styles.loaderContainer}>
        <Loader size="big" active inline="centered">
          Cargando geocercas...
        </Loader>
      </Container>
    );
  }
  changeAreaType(type, areano) {
    let aux = this.state.areasTypes;
    let obj = _.findIndex(aux, { areano: areano });
    aux[obj].tipo = type;
    let index = _.findIndex(this.state.selectedAreas, { areano: areano });
    if (index > -1) {
      let aux2 = this.state.selectedAreas;
      aux2[index].tipo = type;
      this.setState({ selectedAreas: aux2 });
    }
    this.setState({ areasTypes: aux, areas: this.state.areas });
  }
  addVehicle(objectno) {
    if (objectno === "todos") {
      if (this.state.selectedVehicles.length === this.state.lboxAux.length) {
        this.setState({ selectedVehicles: [] });
      } else {
        this.setState({ selectedVehicles: this.state.lboxAux });
      }
    } else {
      let lbox = _.find(this.state.lboxAux, { objectno: objectno });
      let index = _.findIndex(this.state.selectedVehicles, lbox);
      let aux = this.state.selectedVehicles;
      if (index >= 0) {
        aux.pop(lbox);
      } else {
        aux.push(lbox);
      }
      this.setState({ selectedVehicles: aux });
    }
  }
  async addArea(obj) {
    const { account, password, username } = this.props;
    let areaType = _.find(this.state.areasTypes, {
      areano: obj.areano,
      tipoG: obj.tipoG ? obj.tipoG : obj.tipo,
    });

    obj.tipoG = obj.tipoG ? obj.tipoG : obj.tipo;
    obj.tipo = areaType.tipo;
    let index = _.findIndex(this.state.selectedAreas, {
      areano: obj.areano,
      tipoG: obj.tipoG ? obj.tipoG : obj.tipo,
    });
    let aux = this.state.selectedAreas;
    if (index >= 0) {
      delete aux[index];
    } else {
      if (obj.tipoG === "Poligono" || obj.tipoG === "Corredor") {
        await this.props.getCoordinates(
          account,
          username,
          password,
          obj.areano
        );
        if (this.props.coordinates) {
          obj.latitud = this.props.coordinates.latitud;
          obj.longitud = this.props.coordinates.longitud;
          obj.radio = this.props.coordinates.radio;
          aux.push(obj);
        } else {
          alert(
            "Ocurrio un error al obtener los datos de la geocerca. Vuelva a intentar"
          );
        }
      } else {
        aux.push(obj);
      }
    }
    this.setState({ selectedAreas: aux });
  }
  renderNotValidVehicles() {
    return this.state.invalidVehiclesWithPlants.map((vehicle) => {
      return (
        <tr>
          <td>{vehicle.lboxData.objectno}</td>
          <td>{vehicle.lboxGeofence.nombre}</td>
        </tr>
      );
    });
  }
  renderVehicles() {
    if (this.state.areas.length > 0) {
      return this.state.areas.map((area) => {
        let obj = _.find(this.state.areasTypes, { areano: area.areano });
        let index = _.findIndex(this.state.selectedAreas, {
          areano: area.areano,
        });
        return (
          <tr>
            <th style={{ width: 30 }}>
              {" "}
              <Form.Check
                type="checkbox"
                onClick={() => this.addArea(area)}
                checked={index > -1 ? true : false}
              />
            </th>
            <td>{area.nombre}</td>
            <td>{area.tipo}</td>
            <th style={{ width: 30 }}>
              {" "}
              <Form.Check
                onClick={() => this.changeAreaType(1, area.areano)}
                type="radio"
                checked={obj.tipo === 1 ? true : false}
              />
            </th>
            <th style={{ width: 30 }}>
              {" "}
              <Form.Check
                onClick={() => this.changeAreaType(2, area.areano)}
                type="radio"
                checked={obj.tipo === 2 ? true : false}
              />
            </th>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td></td>
          <td>No se encontró ninguna geocerca.</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      );
    }
  }
  filterForText(text) {
    if (text === "") {
      this.setState({ areas: this.props.areasWebfleet });
    }
    const newArray = this.props.areasWebfleet.filter((item) => {
      const textData = String(text).toUpperCase();
      const areaname = String(item.nombre).toUpperCase();

      if (areaname.indexOf(textData) !== -1) {
        return areaname.indexOf(textData) > -1;
      }
    });

    this.setState({ areas: newArray });
  }

  filterVehicles(text) {
    if (text === "") {
      this.setState({ lboxAux: this.props.lboxs });
    }
    const newArray = this.props.lboxs.filter((item) => {
      const textData = String(text).toUpperCase();
      const vehiclesname = String(item.objectno).toUpperCase();

      if (vehiclesname.indexOf(textData) !== -1) {
        return vehiclesname.indexOf(textData) > -1;
      }
    });

    this.setState({ lboxAux: newArray });
  }

  renderSelectedAreas() {
    if (this.state.selectedAreas.length > 0) {
      return this.state.selectedAreas.map((area) => {
        return (
          <AnyReactComponent
            lat={area.latitud}
            lng={area.longitud}
            name={area.nombre}
            radio={area.radio}
            tipo={area.tipo}
          />
        );
      });
    }
  }
  renderVehiclesModal() {
    if (this.state.lboxAux.length > 0) {
      return this.state.lboxAux.map((lbox) => {
        let index = _.findIndex(this.state.selectedVehicles, lbox);
        return (
          <tr>
            <td>
              <Form.Check
                type="checkbox"
                onClick={() => this.addVehicle(lbox.objectno)}
                checked={index >= 0 ? true : false}
              />
            </td>
            <td>{lbox.objectno}</td>
          </tr>
        );
      });
    }
    return (
      <tr>
        <td></td>
        <td>Sin datos seleccionados</td>
      </tr>
    );
  }

  async handlegetAreasLbox(obj) {
    await this.props.getAreasLbox(
      this.props.account,
      this.props.username,
      this.props.password,
      obj.objectno,
      obj.deviceid
    );
    await this.sleep(500);
    if (this.props.areasLbox && this.props.areasLbox.length > 0) {
      await this.setState({ vehicleAvailable: false });
    } else {
      await this.setState({ vehicleAvailable: true });
    }
  }

  async handleVehicleValidation() {
    const { account, username, password, sendArea } = this.props;
    let invalidVehicles = [];
    for (let i = 0; i < this.state.selectedVehicles.length; i++) {
      await this.sleep(100);
      this.setState({
        actualVehicleInProgresBar: this.state.selectedVehicles[i].objectno,
      });
      let lbox = this.state.selectedVehicles[i];
      await this.handlegetAreasLbox(lbox);
      for (let j = 0; j < this.state.selectedAreas.length; j++) {
        await this.sleep(500);
        let area = this.state.selectedAreas[j];
        this.setState({ progresBarInitialValue: j });
        let aux = this.props.areasLbox.find((obj) => obj.addrnr == area.areano);
        if (aux) {
          let objAux = {
            lboxData: lbox,
            lboxGeofence: aux,
          };
          invalidVehicles.push(objAux);
        } else {
          await sendArea(
            account,
            username,
            password,
            lbox.objectno,
            lbox.deviceid,
            area.latitud,
            area.longitud,
            area.radio,
            area.tipo,
            area.areano,
            area.nombre
          );
          await this.sleep(1000);
          if (this.props.message) {
            alert(this.props.message);
          }
        }
      }
      await this.sleep(500);
      this.setState({
        progresBarInitialValue: this.state.selectedAreas.length,
      });
    }
    if (
      this.state.selectedAreas.length >
      this.state.invalidVehiclesWithPlants.length
    ) {
      alert("Areas enviadas. Puedes verificarlo en el apartado de progreso.");
    }
    await this.sleep(500);
    this.setState({ actualVehicleInProgresBar: "Terminado" });
    this.setState({
      progresBarInitialValue: this.state.selectedAreas.length,
    });
    await this.sleep(500);
    await this.setState({ invalidVehiclesWithPlants: invalidVehicles });

    this.setState({ actualVehicleInProgresBar: "" });
    this.setState({ progresBarInitialValue: 0 });
    this.setState({ openModalWithProgresBar: false });

    if (this.state.invalidVehiclesWithPlants.length > 0) {
      this.setState({ notUpdatedVehiclesModal: true });
    } else {
      this.setState({ notUpdatedVehiclesModal: false });
    }
  }

  renderAreasModal() {
    if (this.state.selectedAreas.length > 0) {
      const uniqueValuesSet = new Set();
      const filteredArr = this.state.selectedAreas.filter((obj) => {
        const isPresentInSet = uniqueValuesSet.has(obj.areano);
        uniqueValuesSet.add(obj.areano);
        return !isPresentInSet;
      });
      this.state.selectedAreas = filteredArr;
      return this.state.selectedAreas.map((area) => {
        return (
          <tr>
            <td>{area.nombre}</td>
            <td>{area.tipo === 1 ? "Segura" : "Insegura"}</td>
            <td>
              <img
                onClick={() => this.addArea(area)}
                style={{ width: 25, height: 25 }}
                src={"http://168.235.83.246/images/basura.png"}
              />
            </td>
          </tr>
        );
      });
    }
    return (
      <tr>
        <td></td>
        <td>Sin vehículos</td>
      </tr>
    );
  }
  async sendAreas() {
    if (this.state.selectedAreas.length === 0) {
      alert("No existen areas seleccionadas");
    } else if (this.state.selectedVehicles.length === 0) {
      alert("No existen vehículos seleccionados");
    } else {
      this.setState({ showModal: false, openModalWithProgresBar: true });
      await this.sleep(500);
      await this.handleVehicleValidation();
    }
  }

  renderNotUpdatedVehiclesModal() {
    return (
      <Modal
        show={this.state.notUpdatedVehiclesModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => this.setState({ notUpdatedVehiclesModal: false })}
      >
        <Modal.Header>
          <h5>Geocercas no actualizadas</h5>
        </Modal.Header>
        <Modal.Body>
          <div style={{ fontWeight: "bold" }}>
            Estas geocercas ya se encuentran almacenadas en lbox.
          </div>
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              width: "100%",
              backgroundColor: "white",
              maxHeight: "40vh",
            }}
          >
            <Table responsive>
              <thead style={styles.tableHeader}>
                <tr>
                  <th>vehículo</th>
                  <th>Geocerca</th>
                </tr>
              </thead>
              <tbody>{this.renderNotValidVehicles()}</tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "gray", color: "white", width: 100 }}
            fluid
            onClick={() => this.setState({ notUpdatedVehiclesModal: false })}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderModal() {
    return (
      <Modal
        show={this.state.showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => this.setState({ showModal: false })}
      >
        <Modal.Header>
          <h5>Añadir geocercas</h5>
        </Modal.Header>
        <Modal.Body>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{ display: "flex", width: "33%", flexDirection: "column" }}
            >
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Seleccione los vehículos:
              </span>
              <Form style={{ marginBottom: -14 }}>
                <Form.Group
                  style={{
                    backgroundColor: config.colors.strongGray,
                    padding: 10,
                  }}
                >
                  <Form.Control
                    type="text"
                    placeholder="Buscar vehículo..."
                    onChange={(e) => this.filterVehicles(e.target.value)}
                  />
                </Form.Group>
              </Form>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "90%",
                  maxHeight: 500,
                }}
              >
                <Table responsive bordered>
                  <thead>
                    <th>
                      <Form.Check
                        type="checkbox"
                        onClick={() => this.addVehicle("todos")}
                      />
                    </th>
                    <th>Vehículo</th>
                  </thead>
                  <tbody>{this.renderVehiclesModal()}</tbody>
                </Table>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                width: "63%",
                flexDirection: "column",
                marginLeft: 10,
              }}
            >
              <span style={{ fontSize: 16, fontWeight: "bold" }}>
                Areas a ingresar en los vehículos:
              </span>
              <div style={{ display: "flex", width: "100%", height: "90%" }}>
                <Table responsive bordered>
                  <thead>
                    <th>Nombre</th>
                    <th>Tipo</th>
                    <th>Eliminar</th>
                  </thead>
                  <tbody>{this.renderAreasModal()}</tbody>
                </Table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "gray", color: "white", width: 100 }}
            fluid
            onClick={() => this.setState({ showModal: false })}
          >
            Cancelar
          </Button>
          <Button
            loading={this.state.sendingAreas}
            style={{
              backgroundColor: config.colors.green,
              color: "white",
              width: 100,
            }}
            fluid
            onClick={() => this.sendAreas()}
          >
            Añadir
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  async reSendAllAreaQueue() {
    this.setState({ showReSendProgressModal: true });
    for (let i = 0; i < this.props.areaQueue.length; i++) {
      if ( ["Por enviar", "Error"].includes(this.props.areaQueue[i].status)) {
        await this.reSendAreaToLBox(this.props.areaQueue[i].id, true);
      }
    }
    this.setState({ showReSendProgressModal: false });
  }
  async reSendAreaToLBox(id, flag) {
    const { account, username, password, resendArea, getAreaQueue } =
      this.props;
    this.setState({ resendingArea: id });
    await resendArea(account, username, password, id);
    let message = this.props.message;
    await getAreaQueue(account, username, password);
    this.setState({ resendingArea: null });
    if (flag !== true) {
      alert(message);
    }
  }
  async deletePedingMultipleAreas() {
    this.setState({ showReSendProgressModal: true });
    this.setState({ showDeleteProgressModal: true });
    const {
      account,
      username,
      password,
      deletePedingMultipleAreas,
      getAreaQueue,
    } = this.props;
    await deletePedingMultipleAreas(account, username, password);
    await getAreaQueue(account, username, password);
    this.setState({ showReSendProgressModal: false });
    this.setState({ showDeleteProgressModal: false });
  }

  async deletePendingArea(area) {
    const { account, username, password, deletePedingArea } = this.props;
    this.setState({ erasing: area.id });
    await deletePedingArea(account, username, password, area.objectno, area.id);
    this.setState({ erasing: null });
    alert(this.props.message);
  }
  renderAreasOnModal() {
    let limitDate = moment().subtract(1, "day").utc(false);
    return this.props.areaQueue.map((item) => {
      let date = moment.utc(item.id, "YYYYMMDDHHmmss");
      let sendAgain = false;
      this.state.installer
        ? (sendAgain = false)
        : (sendAgain = date.isBefore(limitDate));
      if (BLOCK_STATUS.includes(item.status)) {
        sendAgain = false;
      }
      return (
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
            marginTop: 5,
          }}
        >
          <span style={{ width: "20%", fontSize: 15 }}>{item.objectno}</span>
          <span style={{ width: "40%", fontSize: 15 }}>{item.addrname1}</span>
          {sendAgain === "true" ? (
            <Button
              loading={this.state.resendingArea === item.id ? true : false}
              fluid
              floated
              onClick={() => this.reSendAreaToLBox(item.id)}
              style={{
                backgroundColor: config.colors.blue,
                width: "20%",
                color: "white",
              }}
            >
              Reenviar
            </Button>
          ) : (
            <span style={{ width: "20%", fontSize: 15 }}>{item.status}</span>
          )}
          {this.state.installer === "true" ? (
            <div
              style={{
                width: "20%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {!BLOCK_STATUS.includes(item.status) && 
              <>
              <Button
                loading={this.state.resendingArea === item.id ? true : false}
                fluid
                floated
                onClick={() => this.reSendAreaToLBox(item.id)}
                style={{ backgroundColor: config.colors.blue, color: "white" }}
                icon="sync"
              ></Button>
                <Button
                  loading={this.state.erasing === item.id ? true : false}
                  fluid
                  floated
                  onClick={() => this.deletePendingArea(item)}
                  style={{ backgroundColor: "red", color: "white" }}
                  icon="delete"
                ></Button>
              </>}
            </div>
          ) : (
            <div />
          )}
        </div>
      );
    });
  }
  renderProgressModalWithProgresBar() {
    return (
      <Modal
        show={this.state.openModalWithProgresBar}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => this.setState({ openModalWithProgresBar: false })}
      >
        <Modal.Header>
          <h5>Validando geocercas con vehiculos</h5>
        </Modal.Header>
        <Modal.Body>
          <Progress
            value={this.state.progresBarInitialValue}
            total={this.state.selectedAreas.length}
            color="olive"
          />
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <div>{`${this.state.actualVehicleInProgresBar}...`}</div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  renderProgressModal() {
    return (
      <Modal
        show={this.state.showModalProgress}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => this.setState({ showModalProgress: false })}
      >
        <Modal.Header>
          <h5>Lista de vehículos en espera</h5>
        </Modal.Header>
        <Modal.Body>
          {this.state.showReSendProgressModal ? (
            <Container style={styles.loader}>
              {this.state.showDeleteProgressModal ? (
                <Loader size="massive" active inline="centered">
                  Eliminando geocercas...
                </Loader>
              ) : (
                <Loader size="massive" active inline="centered">
                  Reenviando geocercas...
                </Loader>
              )}
            </Container>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <span
                  style={{ width: "20%", fontSize: 16, fontWeight: "bold" }}
                >
                  Vehículo
                </span>
                <span
                  style={{ width: "40%", fontSize: 16, fontWeight: "bold" }}
                >
                  Geocerca
                </span>
                <span
                  style={{ width: "20%", fontSize: 16, fontWeight: "bold" }}
                >
                  Estado
                </span>
                {this.state.installer == "true" ? (
                  <span
                    style={{ width: "20%", fontSize: 16, fontWeight: "bold" }}
                  >
                    Acciones
                  </span>
                ) : (
                  <div />
                )}
              </div>
              {this.renderAreasOnModal()}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => this.setState({ showModalProgress: false })}
          >
            Cerrar
          </Button>
          <Button
            class="ui primary button"
            onClick={() => this.reSendAllAreaQueue()}
          >
            Reenviar todo
          </Button>
          <Button
            class="ui primary button"
            onClick={() =>
              this.deletePedingMultipleAreas(/*this.props.areaQueue*/)
            }
          >
            Eliminar todo
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  renderAlert() {
    return (
      <Alert variant="primary">
        Añadiendo geocercas en progreso.
        <Alert.Link onClick={() => this.setState({ showModalProgress: true })}>
          {" "}
          Ver progreso.{" "}
        </Alert.Link>
      </Alert>
    );
  }
  async getAreaQueue(flag) {
   await this.props.getAreaQueue(
      this.props.account,
      this.props.username,
      this.props.password
    );

    await this.sleep(30000);
    if (flag) {
    } else {
      this.getAreaQueue();
    }
  }
  render() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          backgroundColor: "#C9CAD9",
          height: "92.35%",
          flexDirection: "column",
        }}
      >
        {this.props.areaQueue.length > 0 ? this.renderAlert() : <div />}
        <div style={styles.container}>
          <div style={styles.form}>
            <Form style={{ marginBottom: -14 }}>
              <Form.Group>
                <Form.Label style={{ fontSize: 24 }}>
                  Añadir geocercas
                </Form.Label>
                <Form.Text
                  style={{ fontSize: 14, width: "90%", textAlign: "justify" }}
                >
                  La geocerca se guardrá dentro del LBox, estas pueden ser de
                  dos tipos: seguras e inseguras, las cuales brindarán más
                  seguridad a tus vehículos.
                </Form.Text>
              </Form.Group>
              <Form.Group
                style={{
                  backgroundColor: config.colors.strongGray,
                  padding: 10,
                }}
              >
                <Form.Control
                  type="text"
                  placeholder="Buscar geocerca..."
                  onChange={(e) => this.filterForText(e.target.value)}
                />
              </Form.Group>
            </Form>
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                width: "100%",
                backgroundColor: "white",
                height: "60%",
              }}
            >
              <Table responsive>
                <thead style={styles.tableHeader}>
                  <tr>
                    <th style={{ width: 30 }}></th>
                    <th>Nombre</th>
                    <th>tipo</th>
                    <th>Segura</th>
                    <th>Insegura</th>
                  </tr>
                </thead>
                {this.state.loading ? (
                  this.renderLoader()
                ) : (
                  <tbody>{this.renderVehicles()}</tbody>
                )}
              </Table>
            </div>
            <Button
              onClick={() => this.setState({ showModal: true })}
              style={{
                backgroundColor: config.colors.green,
                color: "white",
                marginTop: 10,
              }}
              fluid
            >
              Añadir geocerca
            </Button>
          </div>
          <div style={styles.map}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: config.credentials.apiKeyMap }}
              defaultCenter={this.state.center}
              defaultZoom={this.state.zoom}
            >
              {this.renderSelectedAreas()}
            </GoogleMapReact>
          </div>
          {this.renderModal()}
          {this.renderProgressModal()}
          {this.renderProgressModalWithProgresBar()}
          {this.renderNotUpdatedVehiclesModal()}
        </div>
      </div>
    );
  }
}

const styles = {
  loader: {
    marginTop: 100,
  },
  container: {
    flex: 1,
    flexDirection: "row",
    display: "flex",
    height: "96.35%",
    backgroundColor: config.colors.lightGray,
  },
  form: {
    padding: 10,
    margin: 20,
    width: "30%",
    backgroundColor: "white",
  },
  map: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    margin: 20,
    width: "70%",
    backgroundColor: "white",
  },
  searchbar: {
    widthflex: 1,
    flexDirection: "row",
    display: "flex",
    height: 60,
    padding: 10,
    backgroundColor: config.colors.strongGray,
    width: "100%",
  },
  tableHeader: {
    backgroundColor: config.colors.lightGray,
  },
  loaderContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flex: 1,
    alignItems: "center",
  },
};

const mapStateToProps = (state) => {
  return {
    account: state.auth.account,
    password: state.auth.password,
    username: state.auth.username,
    lboxs: state.lbox.lboxs,
    message: state.lbox.message,
    areasWebfleet: state.lbox.areasWebfleet,
    areaQueue: state.lbox.areaQueue,
    coordinates: state.lbox.coordinates,
    areasLbox: state.lbox.areasLbox,
  };
};
export default connect(mapStateToProps, {
  getLboxs,
  onUsernameChanged,
  onAccountChanged,
  onPasswordChanged,
  getLboxs,
  getAreaQueue,
  getAreasWebfleet,
  getCoordinates,
  sendArea,
  resendArea,
  deletePedingArea,
  deletePedingMultipleAreas,
  getAreasLbox,
})(Area);
