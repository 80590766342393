import _, { orderBy } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Checkbox, Button, Container, Loader, Pagination } from 'semantic-ui-react';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import {
    getLboxs,
    onUsernameChanged,
    onAccountChanged,
    onPasswordChanged,
    getHistory,
    getHistoryByAccount,
    getHistoryByList,
} from '../redux/actions';
import config from '../config';
import "react-datepicker/dist/react-datepicker.css";

const MESSAGE_LIMIT = 100;
const NEW_LBOX_API = 'http://168.235.83.246:7000/api';

class Historial extends Component {
    state = {
        selectedLboxs: [],
        loading: true,
        vehicles: [],
        startDate: new Date(),
        endDate: new Date(),
        today: new Date(),
        formatStartDate: '',
        formatEndDate: '',
        searchDateOption: 1,
        refreshing: false,
        loadingHistory: false,
        history: [],
        searchType: 1,
        page: 1,
        historyCount: 0,
        isSortASC: false,
        orderBy: 'fecha',

    };
    async componentWillMount() {
        let token = await sessionStorage.getItem('token');
        if (!token) {
            alert('Debes de iniciar sesion')
            this.props.history.push('/#')
        }
        else {
            let user = JSON.parse(token)
            this.props.onUsernameChanged(user.username);
            this.props.onAccountChanged(user.account);
            this.props.onPasswordChanged(user.password);
            const { account, username, password } = this.props;
            await this.props.getLboxs(account, username, password);
            let aux = [];

            if (this.props.lboxs.length > 0) {

                this.props.lboxs.map(
                    lbox => {
                        aux.push({ text: lbox.objectno, value: lbox });
                    }
                )
            }
            this.setState({ loading: false, vehicles: aux });
        }
        const { lbox } = this.props.match.params;
        if (lbox !== ":") {
            let objectno = lbox.slice(1, lbox.length);

            if (this.state.vehicles.length > 0) {
                let obj = _.find(this.state.vehicles, { 'text': objectno });
                if (obj !== undefined) {
                    this.setState({ selectedLboxs: [obj.value] });
                    this.searchHistory();
                }
            }
        }

    }
    componentDidMount() {


    }
    handleChange = (e, { value }) => this.setState({ searchDateOption: value });
    handleSearchTypeChange = (e, { value }) => {
        this.setState({ searchType: value });

    };

    async searchHistory(column) {
        const { selectedLboxs, startDate, endDate, searchDateOption, searchType, orderBy, isSortASC } = this.state
        const { account, password, username, } = this.props;
        if (selectedLboxs.length == 0 && this.state.searchType === 1) {
            alert("Debe seleccionar al menos un vehiculo");
            this.setState({ loadingHistory: false });
        }
        else if (searchDateOption === 4 && startDate === endDate) {
            alert("La hora de inicio y fin deben de ser diferentes");
            this.setState({ loadingHistory: false });
        }
        else {
            //Convertir a zona utc para buscar de acuerdo al formato que se guarda en la bd
            this.setState({ loadingHistory: true, history: [], refreshing: true });
            let startDateUtc = ''
            let endDateUtc = ''
            switch (searchDateOption) {
                case 1:
                    let todayStart = moment().format('YYYY-MM-DD 00:00:00');
                    let todayEnd = moment().format('YYYY-MM-DD 23:59:59');

                    startDateUtc = moment(todayStart, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
                    endDateUtc = moment(todayEnd, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
                    break;
                case 2:
                    let yestardayStart = moment().subtract(1, 'day').format('YYYY-MM-DD 00:00:00');
                    let yestardayEnd = moment().subtract(1, 'day').format('YYYY-MM-DD 23:59:59');

                    startDateUtc = moment(yestardayStart, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
                    endDateUtc = moment(yestardayEnd, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
                    break;
                case 3:
                    let weekStart = moment().subtract(6, 'day').format('YYYY-MM-DD 00:00:00');
                    let weekEnd = moment().format('YYYY-MM-DD 23:59:59');

                    startDateUtc = moment(weekStart, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
                    endDateUtc = moment(weekEnd, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
                    break;
                case 4:
                    startDateUtc = moment(startDate, 'DD/MM/YYYY').utc().format('YYYY-MM-DD HH:mm:ss');
                    endDateUtc = moment(endDate, 'DD/MM/YYYY').utc().format('YYYY-MM-DD HH:mm:ss');
                    break;
                default:
                    todayStart = moment().format('YYYY-MM-DD 00:00:00');
                    todayEnd = moment().format('YYYY-MM-DD 23:59:59');
                    startDateUtc = moment(todayStart, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
                    endDateUtc = moment(todayEnd, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
                    break;

            }
            const offset = (this.state.page - 1) * MESSAGE_LIMIT;
            const sortType = isSortASC ? 'ASC' : 'DESC';
            const order = column || orderBy;

            if (this.state.searchType === 2) {
                await this.props.getHistoryByAccount(account, username, password, startDateUtc, endDateUtc, offset, MESSAGE_LIMIT, order, sortType);
            } else {
                const lboxs = selectedLboxs.map(lbox => lbox.objectno);
                await this.props.getHistoryByList(account, lboxs, startDateUtc, endDateUtc, offset, MESSAGE_LIMIT, order, sortType);
            }

            this.setState({ refreshing: false, loadingHistory: false, history: this.props.history, historyCount: this.props.historyCount, formatStartDate: startDateUtc, formatEndDate: endDateUtc })
            if (this.props.message) {
                alert(this.props.message)
            }
        }

    }
    handleChangeEnd = (date) => {

        this.setState({
            endDate: date
        });
    }
    handleChangeStart = (date) => {

        this.setState({
            startDate: date
        });
    }


    downloadCsvReportLink() {

        const type = this.state.searchType === 1 ? 'byObjectnoList' : 'account';
        const objectnoList = this.state.selectedLboxs.map(lbox => lbox.objectno).join(',');
        const paramsObj = {
            account: this.props.account,
            startDate: this.state.formatStartDate,
            endDate: this.state.formatEndDate,
            objectnoList,
            type
        }
        const params = new URLSearchParams(paramsObj).toString();
        return `${NEW_LBOX_API}/messages/download?${params}`
    }

    handlePagination = (e, { activePage }) => {
        this.setState({
            page: activePage
        })
        this.searchHistory()
    }
    handleSortData = (column) => {
        const isSortASC = !this.state.isSortASC
        this.setState({
            orderBy: column,
            isSortASC: isSortASC,
            page: 1
        })
        this.searchHistory(column)
    }
    renderRows() {
        if (this.state.history.length > 0) {
            return this.state.history.map(
                item => {
                    return (
                        <tr>
                            <td style={{ width: '15%' }} >{item.fecha + " " + item.hora}</td>
                            <td style={{ width: '15%' }}>{item.objectno}</td>
                            <td style={{ width: '15%' }}>{item.latitud ? <a href={`http://www.google.com/maps/?q=${(item.latitud / 1000000)},${(item.longitud / 1000000)}`} target="_blank" >{(item.latitud / 1000000) + "," + (item.longitud / 1000000)}</a> : "Sin posición"}</td>
                            <td style={{ width: '15%' }}>{item.odometro ? item.odometro : 'Sin odometro'}</td>
                            <td style={{ width: '40%' }}>{item.aux_data}</td>
                        </tr>
                    );
                }
            )
        } else {
            return (
                <tr>
                    <td>No se encontraron mensajes</td>
                </tr>
            );

        }
    }
    render() {
        const { lbox } = this.props.match.params;
        const OrderIcon = () => <img alt='order button' style={{ width: 15, height: 15, marginRight: 5 }} src={'http://168.235.83.246/images/ordenar.png'} />;

        return (
            <div style={styles.body}>
                <div style={styles.formContainer}>
                    <span style={styles.subtitleText}>Tipos de búsquedas:</span>
                    <Checkbox
                        radio
                        label='Todos los vehiculos'
                        name='SearchTypeGroup'
                        value={2}
                        checked={this.state.searchType === 2}
                        onChange={this.handleSearchTypeChange}
                    />
                    <Checkbox
                        radio
                        label='Por vehículos:'
                        name='SearchTypeGroup'
                        value={1}
                        checked={this.state.searchType === 1}
                        onChange={this.handleSearchTypeChange}
                    />
                    <Dropdown multiple style={{ marginLeft: 20 }} disabled={this.state.searchType !== 1} value={this.state.selectedLboxs} search selection options={this.state.vehicles} onChange={(e, data) => this.setState({ selectedLboxs: data.value })} />

                    <span style={styles.subtitleText}>Formas de búsquedas:</span>
                    <Checkbox
                        radio
                        label='Hoy'
                        name='checkboxRadioGroup'
                        value={1}
                        checked={this.state.searchDateOption === 1}
                        onChange={this.handleChange}
                    />
                    <Checkbox
                        radio
                        label='Ayer'
                        name='checkboxRadioGroup'
                        value={2}
                        checked={this.state.searchDateOption === 2}
                        onChange={this.handleChange}
                    />
                    <Checkbox
                        radio
                        label='Semana actual ( 7 días atras)'
                        name='checkboxRadioGroup'
                        value={3}
                        checked={this.state.searchDateOption === 3}
                        onChange={this.handleChange}
                    />
                    <Checkbox
                        radio
                        label='Búsqueda personalizada'
                        name='checkboxRadioGroup'
                        value={4}
                        checked={this.state.searchDateOption === 4}
                        onChange={this.handleChange}
                    />
                    <div style={{ display: this.state.searchDateOption === 4 ? '' : 'none' }}>
                        <span style={styles.subtitleText} >Fecha de inicio:</span>
                        <div style={styles.calendar}>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                inline={window.innerHeight > 1000 ? true : false}
                                selected={this.state.startDate}
                                onChange={this.handleChangeStart}
                                maxDate={this.state.today}

                            />
                        </div>
                        <span style={styles.subtitleText}>Fecha de fin:</span>
                        <div style={styles.calendar}>
                            <DatePicker
                                dateFormat="dd/MM/yyyy"
                                inline={window.innerHeight > 1000 ? true : false}
                                selected={this.state.endDate}
                                onChange={this.handleChangeEnd}
                                maxDate={this.state.today}
                                minDate={this.state.startDate}
                            />
                        </div>
                    </div>
                    <Button onClick={() => this.searchHistory()} style={styles.searchButton} >Ver historial</Button>
                </div>
                {this.state.loadingHistory ?
                    <Container style={styles.loader}>
                        <Loader size='massive' active inline='centered'  >Cargando historial...</Loader>
                    </Container>
                    :
                    <div style={styles.tableContainer}>
                        <div style={styles.headerTable} >
                            <span style={{ fontSize: 25, color: 'white' }} >Historial</span>
                            <div>
                                <a download={'messages.csv'} href={this.downloadCsvReportLink()}>
                                    <img alt='excel' style={{ width: 25, height: 25, marginRight: 20 }} src={'http://168.235.83.246/images/excel.png'} />                             
                                </a>
                                <Button loading={this.state.refreshing} style={{ backgroundColor: config.colors.green, color: 'white' }} onClick={() => this.searchHistory()} >
                                    <img alt='actualizar' style={{ width: 12, height: 12, marginRight: 5 }} src={'http://168.235.83.246/images/refresh.png'} />
                                    Actualizar
                                </Button>
                            </div>

                        </div>
                        <Table responsive striped bordered hover style={{ boxShadow: '1px 3px 1px #9E9E9E', width: '100%' }}>
                            <thead style={{ backgroundColor: '#E2DDDD' }}>
                                <th style={{ width: '15%' }} onClick={() => this.handleSortData('fecha')}>
                                    <OrderIcon /> Fecha
                                </th>
                                <th style={{ width: '15%' }} onClick={() => this.handleSortData('objectno')}>
                                    <OrderIcon /> Vehículo
                                </th>
                                <th style={{ width: '15%' }} >
                                    Posición
                                </th >
                                <th style={{ width: '15%' }}>
                                    Odómetro
                                </th>
                                <th style={{ width: '40%' }} onClick={() => this.handleSortData('aux_data')}>
                                    <OrderIcon /> Mensaje recibido
                                </th>
                            </thead>
                            <tbody style={{ backgroundColor: 'white' }}>
                                {this.renderRows()}
                            </tbody>
                        </Table>

                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 5 }} >
                            <Pagination totalPages={Math.ceil(this.state.historyCount / MESSAGE_LIMIT)} defaultActivePage={this.state.page} onPageChange={this.handlePagination} />
                        </div>

                    </div>

                }
            </div>
        );
    }
}

const styles = {
    body: {
        display: 'flex', backgroundColor: '#C9CAD9', height: '92.35%', padding: 15

    },
    loader: {
        width: '100%',
        backgroundColor: config.colors.lightGray,
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        alignSelf: 'center',
    },
    formContainer: {
        width: '25%',
        backgroundColor: 'white',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        maxWidth: 330,
        borderRadius: 5,
        boxShadow: '1px 3px 5px #9E9E9E',
        minWidth: 285
    },
    subtitleText: {
        fontSize: 16,
        marginBottom: 10,
        marginTop: 20
    },
    calendar: {
        display: 'flex',
        alignSelf: 'center',
        marginBottom: 10
    },
    rightContainer: {
        display: 'flex',
        width: '80%',
        height: '100%',
        flexDirection: 'column'
    },
    headerTable: {
        display: 'flex',
        width: '100%',
        padding: 5,
        backgroundColor: '#717188',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    tableContainer: {
        display: 'flex',
        height: '100%',
        width: '100%',
        marginLeft: 15,
        flexDirection: 'column'
    },
    searchButton: {
        backgroundColor: config.colors.blue,
        color: 'white',
        marginTop: window.innerHeight > 1000 ? 5 : 30
    },
    refreshButton: {
        backgroundColor: config.colors.green,
        color: 'white',
        marginLeft: 45
    }
};

const mapStateToProps = state => {
    return {
        account: state.auth.account,
        password: state.auth.password,
        username: state.auth.username,
        lboxs: state.lbox.lboxs,
        message: state.lbox.message,
        history: state.lbox.history,
        historyCount: state.lbox.historyCount
    };
}

export default connect(mapStateToProps, {
    getLboxs,
    onUsernameChanged,
    onAccountChanged,
    onPasswordChanged,
    getHistory,
    getHistoryByAccount,
    getHistoryByList
})(Historial);