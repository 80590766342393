import {
    GET_LBOXS_SUCCESS,
    GET_LBOXS_FAIL,
    ARMAR_LBOX_SUCCESS,
    ARMAR_LBOX_FAIL,
    LIBERAR_LBOX_FAIL,
    LIBERAR_LBOX_SUCCESS,
    ALARMAR_LBOX_FAIL,
    ALARMAR_LBOX_SUCCESS,
    GET_LINK_OUTPUT_STATUS,
    SEND_LINK_OUTPUT_FAIL,
    SEND_LINK_OUTPUT_SUCCESS,
    SET_VEHICLE,
    GET_HISTORY_SUCCESS,
    GET_HISTORY_FAIL,
    GET_LBOXS_PINGS,
    GET_POSITION_SUCCESS,
    GET_POSITION_FAIL,
    SEND_PING_SUCCESS,
    SEND_PING_FAIL,
    GET_QUEUE_AREAS_SUCCESS,
    GET_QUEUE_AREAS_FAIL,
    GET_QUEUE_PINS_FAIL,
    GET_QUEUE_PINS_SUCCESS,
    GET_AREAS_WEBFLEET_SUCCESS,
    GET_AREAS_WEBFLEET_FAIL,
    GET_COORDINATES_SUCESS,
    GET_COORDINATES_FAIL,
    SEND_AREA_SUCCESS,
    SEND_AREA_FAIL,
    GET_AREAS_LBOX_SUCCESS,
    GET_AREAS_LBOX_FAIL,
    DELETE_AREA_LBOX_SUCCESS,
    DELETE_AREA_LBOX_FAIL,
    GET_COMMANDS_SUCCESS,
    GET_COMMANDS_FAIL,
    SEND_AUX_DATA_SUCCESS,
    SEND_AUX_DATA_FAIL,
    GET_AVIABLE_LBOX_SUCCESS,
    GET_AVIABLE_LBOX_FAIL,
    ADD_ACCOUNT_SUCCESS,
    ADD_ACCOUNT_FAIL,
    GET_LINKS_SUCCESS,
    GET_LINKS_FAIL,
    LINK_LBOX,
    RESET_LINK_MESSAGE_QUEUE,
    RESEND_AREA_LBOX_FAIL,
    RESEND_AREA_LBOX_SUCESS,
    GET_INACTIVE_LBOXS,
    GET_ALARMS_BY_DATE,
    GET_SECURE_VEHICLES_BY_DATE,
    GET_ALARMS_BY_VEHICLE_FAIL,
    GET_ALARMS_BY_VEHICLE_SUCESS,
    GET_ERASED_LBOXS,
    RESTORE_LBOX_SUCCESS,
    RESTORE_LBOX_FAIL,
    DELETE_PENDING_AREA,
    UPDATE_ACCOUNT,
    DELETE_ACCOUNT,
    GET_REFERENCES_SUCESS,
    GET_REFERENCES_FAIL,
    GET_SENSORES_SUCCESS,
    GET_SENSORES_FAIL,
    UNLINK_LBOX,
    GET_INSECURE_VEHICLES_BY_DATE,
    DELETE_PENDINGS_AREAS,
    GET_HISTORY_SUCCESS_BY_ACCOUNT
} from './types';
import _ from 'lodash';
import axios from 'axios';
const URL_ROOT = "http://168.235.83.246/robota/"
const NEW_LBOX_API_URL = 'http://168.235.83.246:7000/api';
const URL_WEBFLEET = "https://csv.webfleet.com/extern?lang=en&outputformat=json";

export const unlinkLBox = (account, username, password, objectno) => async dispatch => {
    try {
        let { data } = await axios.get(URL_ROOT, {
            params:{
                Accion:"desvincularLBOX",
                cuenta: account,
                usuario: username,
                password: password,
                objectno: objectno
            }
        })
        if(data.error){
            dispatch({type: UNLINK_LBOX, payload: data.error});
        }else{
            dispatch({type: UNLINK_LBOX, payload: "Desvinculación exitosa"});
        }
    }catch(err){
        dispatch({type: UNLINK_LBOX, payload: "Error al consultar. Vuelva a intentarlo"});
    }
}
export const getSensores = (account, username, password, objectno) => async dispatch =>{
    try {
        let { data } = await axios.get(URL_ROOT, {
            params:{
                Accion:"consultarSensores",
                cuenta: account,
                usuario: username,
                password: password,
                objectno: objectno
            }
        })
        if(data.error){
            dispatch({type: GET_SENSORES_FAIL, payload: data.error});
        }else{
            dispatch({type: GET_SENSORES_SUCCESS, payload: data});
        }
    }catch(err){
        dispatch({type: GET_SENSORES_FAIL, payload: "Error al consultar. Vuelva a intentarlo"});
    }
}
export const getReferences = (account, username, password, objectno ) => async dispatch => {
    try {
        let { data } = await axios.get(URL_ROOT,{
            params:{
                cuenta:account,
                usuario:username,
                password:password,
                objectno:objectno,
                Accion: 'consultarReferencias'
            }
        })
        if(data.error){
            dispatch({type: GET_REFERENCES_FAIL, payload:data.error});
        }else{
            dispatch({type: GET_REFERENCES_SUCESS, payload:data});
        }

    }catch(err){
        dispatch({type: GET_REFERENCES_FAIL, payload:"Ocurrio un error al consultar la referencias. Vuelva a intentar"});
    }
}

export const deletePedingArea= (account,username, password, objectno, idGeocerca) => async dispatch =>{
    try {
        let { data } = await axios.get(URL_ROOT,{
            params: {
                Accion:"borrarGeocercaPendiente",
                cuenta: account,
                usuario: username,
                password: password,
                objectno: objectno,
                idGeocerca: idGeocerca
            }
        })
        if(data.resultado){
            dispatch({type: DELETE_PENDING_AREA, payload: data.resultado});
        }else{
            dispatch({type: DELETE_PENDING_AREA, payload: data.error});
        }
    }catch(err){
        dispatch({type: DELETE_PENDING_AREA, payload:"Error vuelva a intentarlo."});        
    }
}
export const deletePedingMultipleAreas= (account,username, password) => async dispatch =>{
    try {
        let { data } = await axios.get(URL_ROOT,{
            params: {
                Accion:"eliminarGeocercasPendientes",
                cuenta: account,
                usuario: username,
                password: password
            }
        })
        if(data.resultado){
            dispatch({type: DELETE_PENDINGS_AREAS, payload: data.resultado});
        }else{
            dispatch({type: DELETE_PENDINGS_AREAS, payload: data.error});
        }
    }catch(err){
        dispatch({type: DELETE_PENDINGS_AREAS, payload:"Error vuelva a intentarlo."});        
    }
}
export const restoreLBox = (account, username, password, objectuid,friendlyname) => async dispatch =>{
    
    try {
        let { data } = await axios.get(URL_ROOT,{
            params:{
                cuenta: account,
                usuario: username,
                password: password,
                objectuid: objectuid,
                friendlyname: friendlyname,
                Accion: "restaurarLBOX"
            }
        })
    
        if(data.errorMsg){
            dispatch({type: RESTORE_LBOX_FAIL, payload: data.errorMsg})
        }else{
            dispatch({type: RESTORE_LBOX_SUCCESS, payload: data.Resultado})
        }
    }catch(err){
        dispatch({type: RESTORE_LBOX_FAIL, payload: "Ocurrio un error. Vuelva a intentarlo"})
    }
}

export const getErasedLBoxs = (account, username, password) => async dispatch =>{
    try {
        let { data } = await axios.get(URL_ROOT,{
            params:{
                cuenta: account,
                usuario: username,
                password: password,
                Accion: "consultarLboxReconfigurables"
            }
        })
        if(data.error){
            dispatch({type:GET_ERASED_LBOXS, payload: []})
        }else{
            dispatch({type:GET_ERASED_LBOXS, payload: data})
        }
    }catch(err){
        dispatch({type:GET_ERASED_LBOXS, payload: []})
    }
}
export const getAlarmsByVehicle = (account, username, password, objectno, startDate, endDate) => async dispatch =>{
    try {
        let { data } = await axios.get(URL_ROOT, {
            params:{
                cuenta: account,
                usuario: username,
                password: password,
                objectno: objectno,
                fechaInicio: startDate,
                fechaFin: endDate,
                Accion: "consultarAlarmadosPorVehiculo"
            }
        })
        if(data.error){
           dispatch({type: GET_ALARMS_BY_VEHICLE_FAIL, payload: []});   
        }else{
            dispatch({type: GET_ALARMS_BY_VEHICLE_SUCESS, payload: data})
        }
    }catch(error){
        dispatch({type: GET_ALARMS_BY_VEHICLE_FAIL, payload: []});   
    }
}
export const getInSecureVehiclesByDate = (account, username,password,startDate, endDate) => async dispatch =>{
    try {
        let { data } = await axios.get(URL_ROOT,{
            params:{
                cuenta: account,
                password:password,
                usuario:username,
                fechaInicio: startDate,
                fechaFin: endDate,
                Accion: "consultarInsegurosFecha"
            }
        })
        if(data.error){
            
            dispatch({type:GET_INSECURE_VEHICLES_BY_DATE, payload: []})
        }else{
            dispatch({type:GET_INSECURE_VEHICLES_BY_DATE, payload: data})
        }

    }catch(err){
 
        dispatch({type:GET_INSECURE_VEHICLES_BY_DATE, payload: []})
    }
}
export const getSecureVehiclesByDate = (account, username,password,startDate, endDate) => async dispatch =>{
    try {
        let { data } = await axios.get(URL_ROOT,{
            params:{
                cuenta: account,
                password:password,
                usuario:username,
                fechaInicio: startDate,
                fechaFin: endDate,
                Accion: "consultarSegurosFecha"
            }
        })
        if(data.error){
            
            dispatch({type:GET_SECURE_VEHICLES_BY_DATE, payload: []})
        }else{
            dispatch({type:GET_SECURE_VEHICLES_BY_DATE, payload: data})
        }

    }catch(err){
 
        dispatch({type:GET_SECURE_VEHICLES_BY_DATE, payload: []})
    }
}
export const getAlarmsByDate = (account, username, password, startDate, endDate) => async dispatch =>{
    try {
        let { data } = await axios.get(URL_ROOT,{
            params:{
                cuenta: account,
                password:password,
                usuario:username,
                fechaInicio: startDate,
                fechaFin: endDate,
                Accion: "consultarAlarmadosFecha"
            }
        })
        if(data.error){
           
            dispatch({type:GET_ALARMS_BY_DATE, payload: []})
        }else{
    
            dispatch({type:GET_ALARMS_BY_DATE, payload: data})
        }

    }catch(err){
     
        dispatch({type:GET_ALARMS_BY_DATE, payload: []})
    }
}

export const getInactiveLboxs = (account, username, password) => async dispatch =>{
    try {
        let { data } = await axios.get(URL_ROOT,{
            params:{
                cuenta: account,
                password:password,
                usuario:username,
                Accion: "consultarInactivos"
            }
        })
        if(data.error){
         
            dispatch({type:GET_INACTIVE_LBOXS, payload: 0})
        }else{
         
            dispatch({type:GET_INACTIVE_LBOXS, payload: data.length})
        }

    }catch(err){
        dispatch({type:GET_INACTIVE_LBOXS, payload: 0})
    }
}

export const resendArea = (account, username, password, id) => async dispatch =>{
    try { 
        let { data } = await axios.get(URL_ROOT, {
            params:{
                Accion: "reenvioDireccionLBOX",
                cuenta: account,
                usuario: username,
                password: password,
                id: id
            }
        })
   
        if(data.resultado){
          
            dispatch({type: RESEND_AREA_LBOX_SUCESS, payload: "Se envío el área con exito."});
        }
        else{
            dispatch({type: RESEND_AREA_LBOX_FAIL, payload: data.error });
        }
    }catch(err){
    
        dispatch({type: RESEND_AREA_LBOX_FAIL, payload: "Ocurrio un error. Vuelva a intentarlo"})
    }
}
export const resetLinkMessageQueue = (account, username, password, objectno) => async dispatch =>{
    try {
        let { data } = await axios.get(URL_ROOT,{
            params:{
                Accion:"resetLBOX",
                cuenta: account,
                usuario: username,
                password: password,
                objectno:objectno
            }
        })
        if(data.error){
            dispatch({type:RESET_LINK_MESSAGE_QUEUE, payload: data.error})
        }else{
            dispatch({type:RESET_LINK_MESSAGE_QUEUE, payload: "Cola de mensajes limpia. Ya puede enviar comandos otra vez."})
        }
    }catch(err){
    
        dispatch({type:RESET_LINK_MESSAGE_QUEUE,payload: "Ocurrio un error. Vuelva a intentarlo"})
    }
}

export const getLinks = (account, username, password) => async dispatch => {
    try {
        let { data } = await axios.get(URL_ROOT,{
            params:{
                Accion:"consultarLinks",
                cuenta: account,
                usuario: username,
                password: password
            }
        })
        if (data.error){
            dispatch({type: GET_LINKS_FAIL, payload: data.error });

        }else{
            dispatch({type: GET_LINKS_SUCCESS, payload: data });

        }
    }catch(err){
        dispatch({type: GET_LINKS_FAIL, payload: "Ocurrio un error obtener los links disponibles, vuelva a intentarlo." })
    }
}
export const addAccount = (account, username, password, ct, us, ad, nb,apikey) => async dispatch =>{
    try{
        let { data } = await axios.get(URL_ROOT, {
            params: {
                Accion : "agregarCuenta",
                cuenta: account,
                usuario: username,
                password: password,
                ct: ct,
                us:us,
                ad:ad,
                nb:nb,
                apikey:apikey
            }
        })
        if(data.resultado){
            dispatch({type: ADD_ACCOUNT_SUCCESS, payload:"Se añadio la cuenta con exito."})
        }
        else{
            dispatch({type:ADD_ACCOUNT_FAIL, payload: data.error})   
        }
    }catch(err){
       
        dispatch({type:ADD_ACCOUNT_FAIL, payload: "Ocurrio un error, vuelva a intentarlo."})
    }
}
export const updateAccount = (idAccount,account, username, password, ct, us, ad, nb,apikey) => async dispatch =>{
    try{
        let { data } = await axios.get(URL_ROOT, {
            params: {
                Accion : "actualizarCuentas",
                cuenta: account,
                usuario: username,
                password: password,
                ct: ct,
                us:us,
                ad:ad,
                nb:nb,
                apikey:apikey,
                idCuenta: idAccount
            }
        })
        if(data.resultado){
            dispatch({type: UPDATE_ACCOUNT, payload: data.resultado})
        }
        else{
            dispatch({type:UPDATE_ACCOUNT, payload: data.error})   
        }
    }catch(err){
       
        dispatch({type:UPDATE_ACCOUNT, payload: "Ocurrio un error, vuelva a intentarlo."})
    }
}
export const deleteAccount = (idAccount,account, username,password) => async dispatch =>{
    try{
        let { data } = await axios.get(URL_ROOT, {
            params: {
                Accion : "borrarCuenta",
                cuenta: account,
                usuario: username,
                password: password,
                idCuenta: idAccount
            }
        })
        if(data.resultado){
            dispatch({type: DELETE_ACCOUNT, payload: data.resultado})
        }
        else{
            dispatch({type:DELETE_ACCOUNT, payload: data.error})   
        }
    }catch(err){
       
        dispatch({type: DELETE_ACCOUNT, payload: "Ocurrio un error, vuelva a intentarlo."})
    }
}
export const linkLBox = (account, username,  password, objectno, friendlyname, objectiud ) => async dispatch =>{
    try {
        let { data } = await axios.get(URL_ROOT, {
            params:{
                Accion:"vincularLBox",
                cuenta: account,
                usuario: username,
                password: password,
                objectno: objectno,
                friendlyname: friendlyname,
                objectuid: objectiud
            }
        })
        if(data.resultado){
            dispatch({type: LINK_LBOX, payload: "El lbox se vínculo con éxito. Ya puede continuar con la instalación."});
        }else{
            dispatch({type: LINK_LBOX, payload: data.error});
        }
    }catch(err){
      
        dispatch({type: LINK_LBOX, payload: "Ocurrio un error, vuelva a intentarlo."});
    }
}

export const getAviableLbox = (account,username, password) => async dispatch =>{
    try{
        let { data } = await axios.get(URL_ROOT,{
            params:{
                Accion: "consultarLboxDisponibles",
                cuenta: account,
                usuario: username,
                password: password
            }
        })
        if(data.error){
            dispatch({type: GET_AVIABLE_LBOX_FAIL, payload: data.error});
        }else{
            dispatch({type: GET_AVIABLE_LBOX_SUCCESS, payload: data});
        }
    }catch(err){
       
        dispatch({type: GET_AVIABLE_LBOX_FAIL, payload: "Ocurrio un error, vuelva a intentarlo."});
    }
}
export const sendDataLbox = (account, username, password, objectno, deviceid, base64 ) => async dispatch => {
    try {
        let { data } = await axios.get(URL_ROOT, {
            params:{
                Accion: "enviarComandoWebfleet",
                cuenta: account,
                usuario: username,
                password: password,
                deviceid: deviceid,
                objectno: objectno,
                data: base64
            }
        })
        if(data.resultado){
            dispatch({type:SEND_AUX_DATA_SUCCESS, payload: "Comando enviado."})
        }else{
           
            dispatch({type: SEND_AUX_DATA_FAIL, payload: data.error})
        }
    }catch(err){
       
        
        dispatch({type:SEND_AUX_DATA_FAIL, payload: "Ocurrio un error, vuelva a intentarlo."})
    }
}
export const getCommands = (account, username, password) => async dispatch =>{
    try {
        let { data } = await axios.get(URL_ROOT,{
            params:{
                Accion: "consultarComandos",
                cuenta: account,
                usuario: username,
                password: password
            }
        })
        if(data.error){
            dispatch({type:GET_COMMANDS_FAIL, payload: data.error})
        }else{
            dispatch({type:GET_COMMANDS_SUCCESS, payload: data})
        }
    }catch(err){
        dispatch({type:GET_COMMANDS_FAIL, payload: "Ocurrio un error, vuelva a intentarlo."})
    }
}
export const deleteAreaLbox = (account, username, password, id) => async dispatch => {
    try {
        let { data } = await axios.get(URL_ROOT, {
            params: {
                Accion: "borrarAreaLBOX",
                cuenta: account,
                usuario: username,
                password: password,
                dirId: id
            }
        });
        if (data.resultado) {
            dispatch({ type: DELETE_AREA_LBOX_SUCCESS });
        } else {
            dispatch({ type: DELETE_AREA_LBOX_FAIL, payload: data.error })
        }
    }catch(err){
    
        dispatch({type:DELETE_AREA_LBOX_FAIL, payload:"Ocurrio un error, vuelva a intentarlo."});
    }

}
export const getAreasLbox = (account, username, password, objectno, deviceid) => async dispatch => {
    try {
        let { data } = await axios.get(URL_ROOT, {
            params: {
                Accion: "consultarDireccionesLBOX",
                cuenta: account,
                usuario: username,
                password: password,
                objectno: objectno,
                deviceid: deviceid
            }
        })
    
        dispatch({ type: GET_AREAS_LBOX_SUCCESS, payload: data })
    } catch (err) {
    
        dispatch({ type: GET_AREAS_LBOX_FAIL, payload: "Ocurrio un error, vuelva a intentarlo." });
    }
}

function formatPositon( value ){
    let dotLatitudIndex = String(value).indexOf('.');
    let beforeDotLatitud = String(value).slice(0,dotLatitudIndex);
    let afterDotLatitud = String(value).slice(dotLatitudIndex+1, value.length);
    let checkIfNegativeLat =  beforeDotLatitud.indexOf("-");
   if(  checkIfNegativeLat > -1 ){
        let aux = beforeDotLatitud.substring(1,beforeDotLatitud.length);
        beforeDotLatitud = "-" + aux.padStart(3,"0");

    }
    else{
        beforeDotLatitud = beforeDotLatitud.padStart(4,"0");
    }
    if(afterDotLatitud.length > 6 ){
        afterDotLatitud = afterDotLatitud.substring(0,6);
    }
    else{
        afterDotLatitud = afterDotLatitud.padEnd(6,"0");
    }
    return beforeDotLatitud +"."+afterDotLatitud;
}
export const sendArea = (account, username, password, objectno, deviceid, latitud, longitud, radio, tipo, areano, areaname) => async dispatch => {
    try {       
        latitud  = formatPositon(latitud);
        longitud =  formatPositon(longitud);
        let { data } = await axios.get(URL_ROOT, {
            params: {
                Accion: "enviarDireccionesLBOX",
                cuenta: account,
                usuario: username,
                password: password,
                objectno: objectno,
                deviceid: deviceid,
                latitud: latitud,
                longitud: longitud,
                radio: radio,
                addrnr: areano,
                addrname: areaname,
                tipo: tipo
            }
        })
        console.log(data);
       
        if (data.resultado) {
            dispatch({ type: SEND_AREA_SUCCESS });
        } else {
            dispatch({ type: SEND_AREA_FAIL, payload: "Ocurrio un error. Favor de comunicarse con soporte técnico" });
        }

    } catch (err) {
        dispatch({ type: SEND_AREA_FAIL, payload: "Ocurrio un error, vuelva a intentarlo." });
    }
}
export const getCoordinates = (account, username, password, areano) => async dispatch => {
    try {
        let { data } = await axios.get(URL_ROOT, {
            params: {
                Accion: "consultarCoordenadas",
                cuenta: account,
                usuario: username,
                password: password,
                areano: areano
            }
        })
        console.log(data);
        if (data.errorCode == 8011) {
            dispatch({ type: GET_COORDINATES_FAIL, payload: "Número de peticiones excedidas, favor de esperar un minuto y vuelva a intentar." });
        }
        else {
            console.log(data.latitud);
            dispatch({ type: GET_COORDINATES_SUCESS, payload: data })
        }

    } catch (err) {
        
        dispatch({ type: GET_COORDINATES_FAIL, payload: "Ocurrio un error, vuelva a intentarlo." });
    }
}

export const getAreasWebfleet = (account, username, password) => async dispatch => {
    try {
        let { data } = await axios.get(URL_ROOT, {
            params: {
                Accion: "consultarDireccionesWebfleet",
                cuenta: account,
                usuario: username,
                password: password
            }
        })
        dispatch({ type: GET_AREAS_WEBFLEET_SUCCESS, payload: data });
    } catch (err) {
       
        dispatch({ type: GET_AREAS_WEBFLEET_FAIL, payload: 'Ocurrio un error, vuelva a intentarlo.' })
    }
}
export const getAreaQueue = (account, username, password) => async dispatch => {
    try {
        let { data } = await axios.get(URL_ROOT, {
            params: {
                Accion: "consultarPendientes",
                cuenta: account,
                usuario: username,
                password: password,
                tipo: "Area"
            }
        })
        if (data.error) {
            dispatch({ type: GET_QUEUE_AREAS_FAIL, payload: data.error });
        } else {
            dispatch({ type: GET_QUEUE_AREAS_SUCCESS, payload: data });
        }
    } catch (err) {
      
        dispatch({ type: GET_QUEUE_AREAS_FAIL, payload: 'Ocurrio un error, vuelva a intentarlo.' })
    }
}
export const getPinQueue = (account, username, password) => async dispatch => {
    try {
        let { data } = await axios.get(URL_ROOT, {
            params: {
                Accion: "consultarPendientes",
                cuenta: account,
                usuario: username,
                password: password,
                tipo: "Pin"
            }
        })
        if (data.error) {
            dispatch({ type: GET_QUEUE_PINS_FAIL, payload: data.error });
        } else {
            dispatch({ type: GET_QUEUE_PINS_SUCCESS, payload: data });
        }
    } catch (err) {
  
        dispatch({ type: GET_QUEUE_PINS_FAIL, payload: 'Ocurrio un error, vuelva a intentarlo.' })
    }
}
export const sendPingLbox = (account, username, password, deviceid, objectno, pin) => async dispatch => {
    try {
        let { data } = await axios.get(`${URL_ROOT}`, {
            params: {
                Accion: "enviarPinLBOX",
                cuenta: account,
                usuario: username,
                password: password,
                deviceid: deviceid,
                objectno: objectno,
                pin: pin
            }
        })
        if (data.error) {
            dispatch({ type: SEND_PING_FAIL, payload: data.error });
        }
        else {
            dispatch({ type: SEND_PING_SUCCESS, payload: `Se envio el pin a la unidad ${objectno} con exito.`  })
        }
    } catch (err) {
       
        dispatch({ type: SEND_PING_FAIL, payload: 'Ocurrio un error, vuelva a intentarlo.' })
    }
}
export const getLinkPosition = (account, username, password, objectno) => async dispatch => {
    try {
        let { data } = await axios.get(URL_ROOT, {
            params: {
                Accion: "consultarUbicacionLink",
                cuenta: account,
                usuario: username,
                password: password,
                objectno,
            }
        });
        if (data.errorCode === 8011) {
            dispatch({ type: GET_POSITION_FAIL, payload: "Limite de consulta de posiciones alcanzadas. Favor de esperar 1 minuto." })
        }
        else if (data.errorCode) {
            dispatch({type: GET_POSITION_FAIL, payload: `Ocurrio un error con webfleet. Código de error: ${data.errorCode}`})
        }
        else {
            dispatch({ type: GET_POSITION_SUCCESS, payload: { address: data? data[0].postext :''  ,coordinates:{lng: data[0].longitude_mdeg / 1000000, lat: data[0].latitude_mdeg / 1000000 }} })
        }
    } catch (err) {
        dispatch({ type: GET_POSITION_FAIL, payload: "Ocurrio un error, vuelve a intentarlo." });
    }
}
export const getPingsLboxs = (account, username, password) => async dispatch => {
    try {
        let { data } = await axios.get(`${URL_ROOT}`, {
            params: {
                Accion: "consultarPinLBOXES",
                cuenta: account,
                usuario: username,
                password: password
            }
        })
        if (data.error) {

            dispatch({ type: GET_LBOXS_PINGS, payload: [] })
        }
        else {

            let aux = _.uniqBy(data, 'objectiud');
            dispatch({ type: GET_LBOXS_PINGS, payload: aux })

        }

    } catch (err) {
        
        dispatch({ type: GET_LBOXS_PINGS, payload: [] })
    }
}
export const getHistoryByAccount = (account, username, password,startDate, endDate, offset, limit, orderBy, sort) => async dispatch => {
    try {
        let { data } = await axios.get(`${NEW_LBOX_API_URL}/messages/byAccount`, {
            params: {
                account,
                startDate: startDate,
                endDate: endDate,
                offset,
                limit,
                orderBy,
                sort,
            }
        })
        if (data.error) {
            dispatch({ type: GET_HISTORY_FAIL, payload: data.error })
        }
        else {
            dispatch({ type: GET_HISTORY_SUCCESS_BY_ACCOUNT, payload: data })
        }

    } catch (err) {
        
        dispatch({ type: GET_HISTORY_FAIL, payload: 'Ocurrio un problema, vuelva a intentar.' })
    }
}
export const getHistoryByList = (account, objectnoList, startDate, endDate, offset, limit, orderBy, sort) => async dispatch => {
    try {
        let { data } = await axios.get(`${NEW_LBOX_API_URL}/messages/byList`, {
            params: {
                account,
                objectnoList: objectnoList.join(","),
                startDate: startDate,
                endDate: endDate,
                offset,
                limit,
                orderBy,
                sort,
            }
        })
        if (data.error) {
            dispatch({ type: GET_HISTORY_FAIL, payload: data.error })
        }
        else {
            dispatch({ type: GET_HISTORY_SUCCESS_BY_ACCOUNT, payload: data })
        }

    } catch (err) {
        
        dispatch({ type: GET_HISTORY_FAIL, payload: 'Ocurrio un problema, vuelva a intentar.' })
    }
}
export const getHistory = (account, username, password, objectno, deviceid, startDate, endDate) => async dispatch => {
    try {
        let { data } = await axios.get(`${URL_ROOT}`, {
            params: {
                Accion: "historialLBOX",
                cuenta: account,
                usuario: username,
                password: password,
                objectno: objectno,
                deviceid: deviceid,
                startDate: startDate,
                endDate: endDate
            }
        })
        if (data.error) {
            dispatch({ type: GET_HISTORY_FAIL, payload: data.error })
        }
        else {
            dispatch({ type: GET_HISTORY_SUCCESS, payload: data })
        }

    } catch (err) {
        
        dispatch({ type: GET_HISTORY_FAIL, payload: 'Ocurrio un problema, vuelva a intentar.' })
    }
}
export const getLboxs = (account, username, password) => async dispatch => {
    try {
        let { data } = await axios.get(`${URL_ROOT}`, {
            params: {
                Accion: "consultarLBOXES",
                cuenta: account,
                usuario: username,
                password: password
            }
        }
        )
        if (data.error) {
            dispatch({ type: GET_LBOXS_FAIL, payload: data.error })
        }
        else {
            dispatch({ type: GET_LBOXS_SUCCESS, payload: data })
        }
    } catch (err) {
        
        dispatch({ type: GET_LBOXS_FAIL, payload: "Ocurrio un error, vuelva a intentarlo" })
    }
}
export const liberarLbox = (objectno, deviceId, serieLbox, account, username, password) => async dispatch => {
    try {
        let { data } = await axios.get(`${URL_ROOT}`, {
            params: {
                Accion: "LBOXliberar",
                serieLBOX: serieLbox,
                objectno: objectno,
                deviceid: deviceId,
                cuenta: account,
                password: password,
                usuario: username,
                data:"ZZZ"
            }
        })
        if (data.error) {

            dispatch({ type: LIBERAR_LBOX_FAIL, payload: data.error })
        }
        else {
            let lbox = await axios.get(`${URL_ROOT}`, {
                params: {
                    Accion: "consultarLBOXES",
                    cuenta: account,
                    usuario: username,
                    password: password
                }
            }
            )
            if (lbox.data.error) {
                dispatch({ type: GET_LBOXS_FAIL, payload: lbox.data.error })
            }
            else {

                dispatch({ type: LIBERAR_LBOX_SUCCESS, payload: { message: "Comando enviado", lboxs: lbox.data } })
            }

        }
    } catch (err) {

        dispatch({ type: LIBERAR_LBOX_FAIL, payload: "Error al enviar el comando, vuelva a intentar" })
    }
}

export const alarmarLbox = (objectno, deviceId, serieLbox, account, username, password) => async dispatch => {
    try {
        let { data } = await axios.get(`${URL_ROOT}`, {
            params: {
                Accion: "LBOXalarmar",
                serieLBOX: serieLbox,
                objectno: objectno,
                deviceID: deviceId,
                cuenta: account,
                password: password,
                usuario: username,
                data:"yyy"
            }
        })
        if (data.error) {

            dispatch({ type: ALARMAR_LBOX_FAIL, payload: data.error })
        }
        else {
            let lbox = await axios.get(`${URL_ROOT}`, {
                params: {
                    Accion: "consultarLBOXES",
                    cuenta: account,
                    usuario: username,
                    password: password
                }
            }
            )
            if (lbox.data.error) {
                dispatch({ type: GET_LBOXS_FAIL, payload: lbox.data.error })
            }
            else {

                dispatch({ type: ALARMAR_LBOX_SUCCESS, payload: { message: "Comando enviado", lboxs: lbox.data } })
            }

        }
    } catch (err) {
     
        dispatch({ type: ALARMAR_LBOX_FAIL, payload: "Error al enviar el comando, vuelva a intentar" })
    }
}

export const armarLbox = (objectno, deviceId, serieLbox, account, username, password) => async dispatch => {
    try {
        let { data } = await axios.get(`${URL_ROOT}`, {
            params: {
                Accion: "LBOXarmar",
                serieLBOX: serieLbox,
                objectno: objectno,
                deviceID: deviceId,
                cuenta: account,
                password: password,
                usuario: username,
                data:"YYY"
            }
        })
        if (data.error) {

            dispatch({ type: ARMAR_LBOX_FAIL, payload: data.error })
        }
        else {
            let lbox = await axios.get(`${URL_ROOT}`, {
                params: {
                    Accion: "consultarLBOXES",
                    cuenta: account,
                    usuario: username,
                    password: password
                }
            }
            )
            if (lbox.data.error) {
                dispatch({ type: GET_LBOXS_FAIL, payload: lbox.data.error })
            }
            else {

                dispatch({ type: ARMAR_LBOX_SUCCESS, payload: { message: "Comando enviado", lboxs: lbox.data } })
            }

        }
    } catch (err) {
   
        dispatch({ type: ARMAR_LBOX_FAIL, payload: "Error al enviar el comando, vuelva a intentar" })
    }
}

export const getOutputStatus = (objectno, account, username, password) => async dispatch => {
    try {
        let { data } = await axios.get("https://csv.webfleet.com/extern?lang=en&outputformat=json&action=showObjectReportExtern", {
            params: {
                account: account,
                username: username,
                password: password,
                objectno: objectno,
                apikey: "b75439d1-86ab-425a-b0fd-19a827d35ccd"
            }
        })
        if (data.errorMsg) {
            
            dispatch({ type: GET_LINK_OUTPUT_STATUS, payload: 0 })
        }
        else {
            dispatch({ type: GET_LINK_OUTPUT_STATUS, payload: data[0].ignition })
        }
    }
    catch (err) {
        console.log(err);
    }

}

export const sendOutputStatus = (objectno, account, username, password, ignition) => async dispatch => {
    try {
        let { data } = await axios.get("https://csv.webfleet.com/extern?lang=en&outputformat=json&action=switchOutput", {
            params: {
                account: account,
                username: username,
                password: password,
                apikey: "b75439d1-86ab-425a-b0fd-19a827d35ccd",
                status: ignition,
                objectno: objectno
            }

        })
        if (data.errorMsg) {
            dispatch({ type: SEND_LINK_OUTPUT_FAIL, payload: data.errorMsg })
        }
        else {
            dispatch({ type: SEND_LINK_OUTPUT_SUCCESS, payload: "Comando enviado" })
        }
    } catch (err) {
        dispatch({ type: SEND_LINK_OUTPUT_FAIL, payload: "Error al enviar el comando, vuelva a intentar" })
    }
}

export const setVehicle = (text) => dispatch => {
    dispatch({ type: SET_VEHICLE, payload: text });
}



