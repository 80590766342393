import React from 'react';
import { HashRouter as Router, Route,Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import './App.css';
import Dashboard from './screens/Dashboard';
import Login from './screens/Login';
import store from './redux/store';
import Main from './stacks/Main';
import 'semantic-ui-css/semantic.min.css'

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/application/" component={Main} />
        <Route  component={Dashboard} />
        </Switch>
       
      </Router>
    </Provider>

  );
}

export default App;
