export const ON_USERNAME_CHANGED = "on_username_changed"
export const ON_ACCOUNT_CHANGED = "on_account_changed";
export const ON_PASSWORD_CHANGED = "on_password_changed";
export const ON_APIKEY_CHANGED = "on_apikey_changed";
export const LOGIN_SUCCESS = "login_success";
export const LOGIN_FAIL = "login_fail";
export const GET_LBOXS_SUCCESS = "get_lbox_success";
export const GET_LBOXS_FAIL = "get_lbox_fail";
export const LIBERAR_LBOX_SUCCESS = "realice_lbox_success";
export const LIBERAR_LBOX_FAIL = "realice_lbox_fail";
export const ARMAR_LBOX_SUCCESS = "realice_lbox_success";
export const ARMAR_LBOX_FAIL = "realice_lbox_fail";
export const ALARMAR_LBOX_SUCCESS = "realice_lbox_success";
export const ALARMAR_LBOX_FAIL = "realice_lbox_fail";
export const GET_LINK_OUTPUT_STATUS = "get_link_output_status";
export const SEND_LINK_OUTPUT_SUCCESS = "send_link_output_success";
export const SEND_LINK_OUTPUT_FAIL = "send_link_output_fail";
export const SET_VEHICLE ="set_vehicle";
export const GET_HISTORY_SUCCESS = "get_history_success";
export const GET_HISTORY_SUCCESS_BY_ACCOUNT = "get_history_success_by_account";
export const GET_HISTORY_FAIL = "get_history_fail";
export const GET_LBOXS_PINGS = "get_lboxs_pings";
export const GET_POSITION_SUCCESS = "get_position_success";
export const GET_POSITION_FAIL = "get_position_fail";
export const SEND_PING_SUCCESS = "send_ping_success";
export const SEND_PING_FAIL = "send_ping_fail";
export const GET_QUEUE_PINS_SUCCESS = "get_queue_pins_success";
export const GET_QUEUE_PINS_FAIL = "get_queue_pins_fail";
export const GET_QUEUE_AREAS_SUCCESS = "get_queue_areas_success";
export const GET_QUEUE_AREAS_FAIL = "get_queue_areas_fail";
export const GET_AREAS_WEBFLEET_SUCCESS = "get_areas_wb_success";
export const GET_AREAS_WEBFLEET_FAIL = "get_areas_wb_fail";
export const GET_COORDINATES_SUCESS = "get_coordinates_success";
export const GET_COORDINATES_FAIL = "get_coordinates_fail";
export const SEND_AREA_SUCCESS = "send_area_success";
export const SEND_AREA_FAIL = "send_area_fail";
export const GET_AREAS_LBOX_SUCCESS = "get_areas_lbox_success";
export const GET_AREAS_LBOX_FAIL = "get_areas_lbox_fail";
export const DELETE_AREA_LBOX_SUCCESS = "delete_area_lbox_success";
export const DELETE_AREA_LBOX_FAIL = "delete_area_lbox_fail";
export const GET_COMMANDS_SUCCESS = "get_commands_success";
export const GET_COMMANDS_FAIL = "get_commands_fail";
export const SEND_AUX_DATA_SUCCESS = "send_aux_data_success";
export const SEND_AUX_DATA_FAIL = "send_aux_data_fail";
export const GET_AVIABLE_LBOX_SUCCESS = "get_aviable_box_success";
export const GET_AVIABLE_LBOX_FAIL ="get_aviable_box_fail";
export const GET_ACCOUNTS_SUCCESS = "get_accounts_success";
export const GET_ACCOUNTS_FAIL = "get_accounts_fail";
export const ADD_ACCOUNT_SUCCESS = "add_account_success";
export const ADD_ACCOUNT_FAIL = "add_account_fail";
export const GET_LINKS_SUCCESS ="get_links_success";
export const GET_LINKS_FAIL ="get_links_fail";
export const LINK_LBOX = "link_lbox";
export const RESET_LINK_MESSAGE_QUEUE = "reset_link_message_queue";
export const RESEND_AREA_LBOX_SUCESS = "resend_area_lbox_success";
export const RESEND_AREA_LBOX_FAIL = "resend_area_lbox_fail";
export const GET_INACTIVE_LBOXS = "get_inactive_lboxs";
export const GET_ALARMS_BY_DATE = "get_alarms_by_date";
export const GET_INSECURE_VEHICLES_BY_DATE = "get_insecure_vehicles_by_date";
export const GET_SECURE_VEHICLES_BY_DATE = "get_secure_vehicles_by_date";
export const GET_ALARMS_BY_VEHICLE_SUCESS = "get_alarms_by_vehicles_sucess";
export const GET_ALARMS_BY_VEHICLE_FAIL = "get_alarms_by_vehicles_fail";
export const GET_ERASED_LBOXS = "get_erased_lboxs";
export const RESTORE_LBOX_SUCCESS = "restores_lbox_success";
export const RESTORE_LBOX_FAIL = "restores_lbox_fail";
export const DELETE_PENDING_AREA = "delete_pending_area";
export const UPDATE_ACCOUNT ="update_account";
export const DELETE_ACCOUNT ="delete_account";
export const GET_REFERENCES_SUCESS = "get_references_success";
export const GET_REFERENCES_FAIL = "get_references_fail";
export const GET_SENSORES_SUCCESS = "get_sensores_success";
export const GET_SENSORES_FAIL = "get_sensores_fail";
export const UNLINK_LBOX = "unlink_lbox";
export const DELETE_PENDINGS_AREAS = "delete_pendings_areas";





