import React, { Component } from 'react';
import { Loader, Container, Dropdown, Button, Grid } from 'semantic-ui-react';
import { Table, Form, FormGroup, Modal, Navbar } from 'react-bootstrap';
import moment from 'moment'
import config from '../config/index'
import { connect } from 'react-redux';
import {
    onAccountChanged,
    onUsernameChanged,
    onApiKeychanged,
    onPasswordChanged,
    getErasedLBoxs,
    restoreLBox
} from '../redux/actions';

class Pruebas extends Component {
    state = {
        erasedLBox: [],
        account: null,
        username: null,
        password: null,
        password2: null,
        name: null,
        loading: false,
        restoring: null,
        refreshing: false
    }
    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    async componentWillMount() {
        let token = await sessionStorage.getItem('token');
        if (!token) {
            alert('Debes de iniciar sesion')
            this.props.history.push('/#')
        }
        else {
            let user = JSON.parse(token)
            this.props.onUsernameChanged(user.username);
            this.props.onAccountChanged(user.account);
            this.props.onPasswordChanged(user.password);
            this.getErasedLBoxs();
        }
    }

    async getErasedLBoxs() {
        this.setState({ refreshing: true });
        const { account, username, password, getErasedLBoxs } = this.props;
        await getErasedLBoxs(account, username, password);
        this.setState({ erasedLBox: this.props.erasedLBoxs,refreshing:false });
    }
    filterForText(text) {
        if (text === "") {

            this.setState({ commands: this.props.commands })
        }
        const newArray = this.props.commands.filter(
            item => {
                const textData = String(text).toUpperCase();
                const commandName = String(item.nombre).toUpperCase();

                if (commandName.indexOf(textData) !== -1) {
                    return commandName.indexOf(textData) > -1
                }
            }
        );



        this.setState({ commands: newArray, data: text });


    };

    renderRows() {
        if (this.state.erasedLBox.length > 0) {
            return this.state.erasedLBox.map(row => {

                return (
                    <tr>
                        <td>{row.objectuid}</td>
                        <td>{row.objectno}</td>
                        <td>{row.friendlyname}</td>
                        <td>{row.account}</td>
                        <td> <Button  loading={this.state.restoring===row.objectuid ? true: false} onClick={() => this.onClickButton(row)} style={{ width: '80%', backgroundColor: config.colors.blue, color: 'white' }} >Restaurar</Button>  </td>
                    </tr>
                )
            })
        } else {
            return (
                <tr>
                    <td>Sin LBoxs.</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            )
        }
    }
    async onClickButton(lbox) {
        this.setState({ restoring: lbox.objectuid });
        let accountData= null;
        for(let i=0; i<this.props.accounts.length;i++){
            let obj = this.props.accounts[i].value
            if(lbox.account ===  obj.account){
                accountData = obj;
            }
        }
        if(accountData!= null){
            await this.props.restoreLBox(accountData.account, accountData.username,accountData.password, lbox.objectuid, lbox.friendlyname);
            alert(this.props.message);
        }else{
            alert("No se encontro ninguna cuenta con esa unidad");
        }
        this.setState({ restoring: null });
    }
    render() {
        if (this.state.erasedLBox.length > 0) {
            return (
                <div style={styles.container}>
                    {this.state.loadingHistory ?
                        <Container style={styles.loaderHistory}>
                            <Loader size='massive' active inline='centered'  >Cargando LBox's...</Loader>
                        </Container>
                        :
                        <div style={styles.tableContainer}>
                            <div style={styles.headerTable} >
                                <span style={{ fontSize: 25, color: 'white' }} >LBox's borradas</span>
                                <Button loading={this.state.refreshing} style={{ backgroundColor: config.colors.green, color: 'white' }}  onClick={() => {this.getErasedLBoxs() }} >
                                <img style={{width:12,height:12, marginRight:5}} src={'http://168.235.83.246/images/refresh.png'} />

                                    Actualizar
                    </Button>
                            </div>
                            <Table responsive striped bordered hover style={{ boxShadow: '1px 3px 1px #9E9E9E', width: '100%' }}>
                                <thead style={{ backgroundColor: '#E2DDDD' }}>
                                    <th>
                                        ObjectUid
                    </th>
                                    <th>
                                        Objectno
                    </th>
                                    <th>
                                        Número serie LBox
                    </th>
                                    <th>
                                        Cuenta
                    </th>
                                    <th>
                                        Restaurar LBox's
                    </th>
                                </thead>
                                <tbody style={{ backgroundColor: 'white' }}>
                                    {this.renderRows()}
                                </tbody>
                            </Table>
                        </div>

                    }
                </div>
            )
        } else {
            return (
                <Container style={styles.loader}>
                    <Loader size='large' active inline='centered'>Cargando cuentas...</Loader>
                </Container>
            )
        }
    }

}

const styles = {
    loader: {
        marginTop: 100
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        display: 'flex',
        height: '92%',
        backgroundColor: config.colors.lightGray, padding: 20,
        justifyContent: 'center'
    },
    form: {
        padding: 20,

        width: '30%',
        backgroundColor: 'white'
    },
    table: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        margin: 10,
        width: '90%',
        backgroundColor: 'white'
    },
    searchbar: {
        widthflex: 1,
        flexDirection: 'row',
        display: 'flex',
        height: 60,
        padding: 10,
        backgroundColor: config.colors.strongGray,
        width: '100%'
    },
    label: {
        flexDirection: 'row',
        display: 'flex',
        marginTop: 20
    },
    headerTable: {
        display: 'flex',
        width: '100%',
        padding: 5,
        backgroundColor: '#717188',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    tableContainer: {
        display: 'flex',
        height: '100%',
        width: '70%',
        marginLeft: 15,
        flexDirection: 'column'
    },
    searchButton: {
        backgroundColor: config.colors.blue,
        color: 'white',
        marginTop: window.innerHeight > 1000 ? 5 : 30
    },
    refreshButton: {
        backgroundColor: config.colors.green,
        color: 'white',
        marginLeft: 45
    }, loaderHistory: {
        width: '70%',
        backgroundColor: config.colors.lightGray,
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        alignSelf: 'center',
    },

}



const mapStateToProps = state => {
    return {
        account: state.auth.account,
        password: state.auth.password,
        username: state.auth.username,
        erasedLBoxs: state.lbox.erasedLBoxs,
        message: state.lbox.message,
        accounts: state.auth.accounts
    };
}
export default connect(mapStateToProps,
    {
        onAccountChanged,
        onUsernameChanged,
        onApiKeychanged,
        onPasswordChanged,
        getErasedLBoxs,
        restoreLBox
    })(Pruebas);