import {
    ON_ACCOUNT_CHANGED,
    ON_USERNAME_CHANGED,
    ON_PASSWORD_CHANGED,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    ON_APIKEY_CHANGED,
    GET_ACCOUNTS_SUCCESS,
    GET_ACCOUNTS_FAIL
} from '../actions/types';


const INITIAL_STATE = {
    account: null,
    username: null,
    password: null,
    message: null,
    accounts: [],
    installer: false,
    apiKey:"",
}
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case ON_ACCOUNT_CHANGED:
            return { ...state, account: action.payload };
        case ON_APIKEY_CHANGED:
            return { ...state, apiKey: action.payload };
        case ON_PASSWORD_CHANGED:
            return { ...state, password: action.payload };
        case ON_USERNAME_CHANGED:
            return { ...state, username: action.payload };
        case LOGIN_SUCCESS:
            return { ...state, 
                    username: action.payload.username,
                    account: action.payload.account, 
                    password: action.payload.password, 
                    apiKey:action.payload.apikey, 
                    installer: action.payload.installer,
                    message: null 
                };
        case LOGIN_FAIL:
            return { ...state, message: action.payload };
        case GET_ACCOUNTS_SUCCESS:
            let aux = [];
            if (action.payload.length>0){
                action.payload.map(
                    item => {
                        aux.push({
                            text: item.name,
                            value: item
                        })
                    }
                );
            } 
            return { ...state, accounts: aux, message: null};
        case GET_ACCOUNTS_FAIL:
            return { ...state, accounts: [], message:action.payload}
        default:
            return state;
    }
}

