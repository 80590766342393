import _ from 'lodash';
import {
    GET_LBOXS_SUCCESS,
    GET_LBOXS_FAIL,
    LIBERAR_LBOX_SUCCESS,
    LIBERAR_LBOX_FAIL,
    ALARMAR_LBOX_FAIL,
    ALARMAR_LBOX_SUCCESS,
    ARMAR_LBOX_FAIL,
    ARMAR_LBOX_SUCCESS,
    GET_LINK_OUTPUT_STATUS,
    SEND_LINK_OUTPUT_SUCCESS,
    SEND_LINK_OUTPUT_FAIL,
    SET_VEHICLE,
    GET_HISTORY_SUCCESS,
    GET_HISTORY_FAIL,
    GET_LBOXS_PINGS,
    GET_POSITION_SUCCESS,
    GET_POSITION_FAIL,
    SEND_PING_SUCCESS,
    SEND_PING_FAIL,
    GET_QUEUE_PINS_SUCCESS,
    GET_QUEUE_PINS_FAIL,
    GET_QUEUE_AREAS_SUCCESS,
    GET_QUEUE_AREAS_FAIL,
    GET_AREAS_WEBFLEET_SUCCESS,
    GET_AREAS_WEBFLEET_FAIL,
    GET_COORDINATES_SUCESS,
    GET_COORDINATES_FAIL,
    SEND_AREA_FAIL,
    SEND_AREA_SUCCESS,
    GET_AREAS_LBOX_SUCCESS,
    GET_AREAS_LBOX_FAIL,
    DELETE_AREA_LBOX_SUCCESS,
    DELETE_AREA_LBOX_FAIL,
    GET_COMMANDS_SUCCESS,
    GET_COMMANDS_FAIL,
    SEND_AUX_DATA_SUCCESS,
    SEND_AUX_DATA_FAIL,
    LINK_LBOX_SUCCESS,
    LINK_LBOX_FAIL,
    GET_AVIABLE_LBOX_FAIL,
    GET_AVIABLE_LBOX_SUCCESS,
    ADD_ACCOUNT_SUCCESS,
    ADD_ACCOUNT_FAIL,
    GET_LINKS_SUCCESS,
    GET_LINKS_FAIL,
    LINK_LBOX,
    RESET_LINK_MESSAGE_QUEUE,
    RESEND_AREA_LBOX_SUCESS,
    RESEND_AREA_LBOX_FAIL,
    GET_INACTIVE_LBOXS,
    GET_SECURE_VEHICLES_BY_DATE,
    GET_ALARMS_BY_DATE,
    GET_ALARMS_BY_VEHICLE_SUCESS,
    GET_ALARMS_BY_VEHICLE_FAIL,
    GET_ERASED_LBOXS,
    RESTORE_LBOX_SUCCESS,
    RESTORE_LBOX_FAIL,
    DELETE_PENDING_AREA,
    UPDATE_ACCOUNT,
    DELETE_ACCOUNT,
    GET_REFERENCES_SUCESS,
    GET_REFERENCES_FAIL,
    GET_SENSORES_SUCCESS,
    GET_SENSORES_FAIL,
    UNLINK_LBOX,
    GET_INSECURE_VEHICLES_BY_DATE,
    GET_HISTORY_SUCCESS_BY_ACCOUNT
} from '../actions/types';


const INITIAL_STATE = {
    lboxs: [],
    message: null,
    ingition : null,
    vehicle: null,
    history: [],
    lboxsPings : [],
    lboxPosition: null,
    result: null,
    pinQueue:[],
    areaQueue:[],
    areasWebfleet:[],
    coutaReached:false,
    coordinates:null,
    areasLbox: [],
    commands:[],
    aviableLbox:[],
    links:[],
    inactiveLboxs: 0,
    alarmsByDate: 0,
    secureByDate: [],
    alarmsByVehicle: [],
    erasedLBoxs:[],
    references: null,
    sensores: null,
    inSecureByDate:[],
    historyCount: 0,
}
export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case GET_LBOXS_SUCCESS:
            let auxLbs = _.orderBy(action.payload)
            return { ...state, lboxs: action.payload, message: null };
        case GET_LBOXS_FAIL:
            return { ...state, message: action.payload, lboxs: [] };
        case LIBERAR_LBOX_SUCCESS:
            return { ...state, message: action.payload.message, lboxs: action.payload.lboxs };
        case LIBERAR_LBOX_FAIL:
            return { ...state, message: action.payload }
        case ALARMAR_LBOX_SUCCESS:
            return { ...state, message: action.payload.message, lboxs: action.payload.lboxs };
        case ALARMAR_LBOX_FAIL:
            return { ...state, message: action.payload }
        case ARMAR_LBOX_SUCCESS:
            return { ...state, message: action.payload.message, lboxs: action.payload.lboxs };
        case ARMAR_LBOX_FAIL:
            return { ...state, message: action.payload }
        case GET_LINK_OUTPUT_STATUS:
            return { ...state, ingition: action.payload}
        case SEND_LINK_OUTPUT_SUCCESS:
            return { ...state, message: action.payload}
        case SEND_LINK_OUTPUT_FAIL:
            return { ...state, message: action.payload};
        case SET_VEHICLE:
            return { ...state, vehicle: action.payload};
        case GET_HISTORY_SUCCESS:
            return { ...state, history: action.payload, message:null}
        case GET_HISTORY_SUCCESS_BY_ACCOUNT:
            return { ...state, history: action.payload.values, historyCount: action.payload.total, message:null}
        case GET_HISTORY_FAIL:
            return { ...state, history:[], historyCount: 0, message:action.payload}
        case GET_LBOXS_PINGS:
            return { ...state, lboxsPings:action.payload, message:null}
        case GET_POSITION_SUCCESS:
            return { ...state, lboxPosition: action.payload,message: null}
        case GET_POSITION_FAIL:
            return { ...state, lboxPosition: null, message: action.payload }
        case SEND_PING_SUCCESS:
            return { ...state, message: action.payload}
        case SEND_PING_FAIL:
            return { ...state,result: false, message: action.payload}
        case GET_QUEUE_PINS_SUCCESS:
            return { ...state, pinQueue: action.payload || [], message: null}
        case GET_QUEUE_PINS_FAIL:
            return { ...state, pinQueue:[], message: action.payload}
        case GET_QUEUE_AREAS_SUCCESS:
            return { ...state, areaQueue: action.payload || [], message:null}
        case GET_QUEUE_AREAS_FAIL:
            return { ...state, areaQueue: [], message: action.payload}
        case GET_AREAS_WEBFLEET_SUCCESS:
            
            if(action.payload.errorCode==8011){
                return { ...state, areasWebfleet:[],message:"Espere unos 2 min y vuelva a actualizar la página." }
            }
            else if(action.payload.errorCode){
                return { ...state, areasWebfleet:[],message:action.payload.errorMsg }
            }
            return { ...state, areasWebfleet:action.payload,message:null  }
        case GET_AREAS_WEBFLEET_FAIL:
            return { ...state, areasWebfleet:[],message:"Ocurrio un error. Vuelva a intentarlo"} 
        case GET_COORDINATES_SUCESS:
            return { ...state, coordinates: action.payload, message: null}
        case GET_COORDINATES_FAIL:
            return { ...state, coordinates: null, message:action.payload}
        case SEND_AREA_FAIL:
            return {...state, message: action.payload}
        case SEND_AREA_SUCCESS:
            return {...state, message: null}
        case GET_AREAS_LBOX_SUCCESS:
            
            return { ...state, message: null, areasLbox: action.payload}
        case GET_AREAS_LBOX_FAIL:
             
            return { ...state, message: action.payload, areasLbox: []}
        case DELETE_AREA_LBOX_SUCCESS:
            return { ...state, message: null}
        case DELETE_AREA_LBOX_FAIL:
            return { ...state, message: action.payload}
        case GET_COMMANDS_SUCCESS:
            return { ...state, commands:action.payload, message: null}
        case GET_COMMANDS_FAIL:
            return { ...state, commands:[], message: action.payload}
        case SEND_AUX_DATA_SUCCESS:
            return { ...state, message: action.payload}
        case SEND_AUX_DATA_FAIL:
            return { ...state, message: action.payload}
        case GET_AVIABLE_LBOX_SUCCESS:
                let auxLboxs= [];
                action.payload.map(
                    item=>{
                        auxLboxs.push({
                            text: item.friendlyName,
                            value: item
                        });
                    }
                )
                return { ...state, aviableLbox: auxLboxs, message: null};
        case GET_AVIABLE_LBOX_FAIL:
            return { ...state, aviableLbox: [], message: action.payload}
        case ADD_ACCOUNT_SUCCESS:
            return { ...state, message: action.payload}
        case ADD_ACCOUNT_FAIL:
            return { ...state, message: action.payload}
        case GET_LINKS_SUCCESS:
            let auxLinks= [];
            action.payload.map(
                item=>{
                    auxLinks.push({
                        text: item.objectno +" / " + item.noSerie,
                        value: item
                    });
                }
            )
            return { ...state, links: auxLinks, message: null};
        case GET_LINKS_FAIL:
            return { ...state, links: [], message: action.payload};
        case LINK_LBOX:
            return { ...state, message: action.payload};
        case RESET_LINK_MESSAGE_QUEUE:
            return { ...state, message: action.payload};
        case RESEND_AREA_LBOX_SUCESS:
            return { ...state, message: action.payload};
        case RESEND_AREA_LBOX_FAIL:
            return { ...state, message: action.payload};
        case GET_INACTIVE_LBOXS: 
            return { ...state, inactiveLboxs: action.payload};
        case GET_ALARMS_BY_DATE:
            return { ...state, alarmsByDate: action.payload};
        case GET_SECURE_VEHICLES_BY_DATE:
            return { ...state, secureByDate: action.payload};
        case GET_INSECURE_VEHICLES_BY_DATE:
            return { ...state, inSecureByDate: action.payload};
        case GET_ALARMS_BY_VEHICLE_SUCESS:
            return { ...state, alarmsByVehicle: action.payload};
        case GET_ALARMS_BY_VEHICLE_FAIL:
            return { ...state, alarmsByVehicle: action.payload};
        case GET_ERASED_LBOXS:
            return { ...state, erasedLBoxs: action.payload};
        case RESTORE_LBOX_FAIL:
            return { ...state, message: action.payload};
        case RESTORE_LBOX_SUCCESS:
            return { ...state, message: action.payload};
        case DELETE_PENDING_AREA:
            return { ...state, message: action.payload};
        case UPDATE_ACCOUNT:
            return { ...state, message: action.payload};
        case DELETE_ACCOUNT:
            return { ...state, message: action.payload};
        case GET_REFERENCES_SUCESS:
            return { ...state, references: action.payload, message:null}
        case GET_REFERENCES_FAIL:
            return { ...state, references:null, message: action.payload}
        case GET_SENSORES_SUCCESS:
            return { ...state, sensores: action.payload, message: null};
        case GET_SENSORES_FAIL:
            return { ...state, sensores: null, message: action.payload};
        case UNLINK_LBOX:
            return { ...state, message: action.payload};
        default:
            return state;
    }
}

