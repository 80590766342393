import React, { Component } from 'react';
import { Loader, Container, Dropdown, Button, Grid } from 'semantic-ui-react';
import { Table, Form, FormGroup, Modal, Navbar } from 'react-bootstrap';
import moment from 'moment'
import config from '../config/index'
import { connect } from 'react-redux';
import {
   getAccounts,
   onAccountChanged,
   onUsernameChanged,
   onApiKeychanged,
   onPasswordChanged,
   addAccount,
   updateAccount,
   deleteAccount
} from '../redux/actions';

class Pruebas extends Component {
    state = {
        accounts:[],
        account:null,
        username:null,
        password: null,
        password2: null,
        apikey:null,
        name: null,
        newAccount:null,
        newUsername:null,
        newPassword: null,
        newApikey:null,
        newName: null,
        loading: false,
        showModal: false,
        selectedAccount: {},
        deleting: false,
        updatading:false
    }
    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    async componentWillMount() {
        let token = await sessionStorage.getItem('token');
        if (!token) {
            alert('Debes de iniciar sesion')
            this.props.history.push('/#')
        }
        else {
            let user = JSON.parse(token)
            this.props.onUsernameChanged(user.username);
            this.props.onAccountChanged(user.account);
            this.props.onPasswordChanged(user.password);
            const { account, username, password, getAccounts } = this.props;
            await getAccounts(account,username,password);
            this.setState({accounts: this.props.accounts});
        }
    }
   
    filterForText(text) {
        if (text === "") {

            this.setState({ commands: this.props.commands })
        }
        const newArray = this.props.commands.filter(
            item => {
                const textData = String(text).toUpperCase();
                const commandName = String(item.nombre).toUpperCase();

                if (commandName.indexOf(textData) !== -1) {
                    return commandName.indexOf(textData) > -1
                }
            }
        );



        this.setState({ commands: newArray, data: text });


    };
    async getAccounts(){
        this.setState({loading:true});
        const { account, username, password, getAccounts } = this.props;
        await getAccounts(account, username, password);
        this.setState({accounts: this.props.accounts});
        this.setState({loading:false});
    }
    async onDeleteAccount(idAccount){
        this.setState({deleting:true});
        const { account, username, password, deleteAccount, getAccounts } = this.props;
        await deleteAccount(idAccount, account, username, password);
        alert(this.props.message);
        this.setState({deleting:false, showModal:false});
        this.getAccounts();
    }
    async onUpdateAccount(idAccount){
        this.setState({updatading:true });
        const { account, username, password, updateAccount, getAccounts } = this.props;
        const { newName, newAccount,newUsername, newPassword, newApikey} = this.state;
        await  updateAccount(idAccount, this.props.account, this.props.username, this.props.password, newAccount, newUsername, newPassword,newName, newApikey);
        alert(this.props.message);
        this.setState({updatading:false, showModal:false});
        this.getAccounts();
    }
    renderModal(){
        return (
            <Modal
            show={this.state.showModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => this.setState({ showModal: false })}

        >

            <Modal.Header>
                <h5>Detalles cuenta {this.state.selectedAccount.name}</h5>
            </Modal.Header>
            <Modal.Body >
                <div style={{ display: 'flex', flexDirection: 'row',justifyContent: 'space-evenly'}}>
                    <div style={{ display: 'flex', width: '90%', justifyContent: 'space-between',flexDirection:'column' }}>
                        <Form.Group>
                            <Form.Label>Nombre del cliente:</Form.Label>
                            <Form.Control type="text" placeholder="ej. InRoute" onChange={(e) => this.setState({ newName: e.target.value })} value={this.state.newName} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Cuenta:</Form.Label>
                            <Form.Control  type="text" placeholder="ej. InRoute" onChange={(e) => this.setState({ newAccount: e.target.value })} value={this.state.newAccount} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Usuario:</Form.Label>
                            <Form.Control type="text" placeholder="ej. InRoute" onChange={(e) => this.setState({ newUsername: e.target.value })} value={this.state.newUsername} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Contraseña:</Form.Label>
                            <Form.Control type="text" placeholder="ej. InRoute" onChange={(e) => this.setState({ newPassword: e.target.value })} value={this.state.newPassword} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Apikey:</Form.Label>
                            <Form.Control type="text" placeholder="ej. 3434b43b23bbb33222" onChange={(e) => this.setState({ newApikey: e.target.value })} value={this.state.newApikey} />
                        </Form.Group>
                        
                    </div>
                  
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={ () => this.setState({showModal: false})} >Cancelar</Button>
                <Button loading={this.state.deleting} onClick={ () => this.onDeleteAccount(this.state.selectedAccount.id)} style={{color:'white',backgroundColor:'red'}}>Eliminar cuenta</Button>
                <Button loading={this.state.updatading} onClick={() => this.onUpdateAccount(this.state.selectedAccount.id)}   style={{color:'white',backgroundColor:config.colors.blue}}>Guardar cambios</Button>
            </Modal.Footer>
        </Modal>
    );
    }
    renderRows(){
        if (this.state.accounts.length > 0) {
            return this.state.accounts.map(row => {
               
                return (
                    <tr onClick={()=> this.setState({selectedAccount: row.value,showModal:true, newName:row.value.name,newAccount: row.value.account,newUsername:row.value.username, newPassword:row.value.password, newApikey: row.value.apiKey  })} >
                        <td>{row.value.name}</td>
                        <td>{row.value.account}</td>
                        <td>{row.value.username}</td>
                        {/* <td>{row.value.password}</td> */}
                        <td>{row.value.apiKey}</td>
                    </tr>
                )
            })
        } else {
            return (
                <tr>
                    <td>Sin cuentas.</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            )
        }
    }
    async onClickButton() {
        
        if(this.state.apikey =="" || this.state.apikey == null || this.state.account =="" || this.state.account == null ||this.state.username =="" || this.state.username == null || this.state.password =="" || this.state.password == null ||this.state.name =="" || this.state.name == null){
            alert("Faltan datos por llenar.")
        }else if( this.state.password !== this.state.password2){
            alert ("Las contraseñas no coinciden");
        }
        else{
            this.setState({loading: true});
            const { account, username, password, addAccount,getAccounts } = this.props;
            await addAccount(account, username, password, this.state.account, this.state.username, this.state.password, this.state.name,this.state.apikey);
            await getAccounts(account,username,password);
            alert(this.props.message);
            this.setState({loading: false,accounts: this.props.accounts, account: null, password:null, password2:null, username:null, name: null, apikey:null});
        }

        
    }
    render() {
        if (this.state.accounts.length > 0) {
            return (
                <div style={styles.container}>
                    <div style={styles.form}>
                        <h2>Administración de cuentas.</h2>
                        <Form.Group>
                                <Form.Label>Nombre del cliente:</Form.Label>
                                <Form.Control type="text" placeholder="ej. InRoute" onChange={(e) => this.setState({ name: e.target.value })}  value={this.state.name} />
                        </Form.Group>
                        <Form.Group>
                                <Form.Label>Nombre de la cuenta(webfleet):</Form.Label>
                                <Form.Control type="text" placeholder="ej. galac comercializadora" onChange={(e) => this.setState({ account: e.target.value })} value={this.state.account} />
                        </Form.Group>
                        <Form.Group>
                                <Form.Label>Usuario:</Form.Label>
                                <Form.Control type="text" placeholder="ej. lbox" onChange={(e) => this.setState({ username: e.target.value })} value={this.state.username} />
                        </Form.Group>
                        <Form.Group>
                                <Form.Label>Contraseña:</Form.Label>
                                <Form.Control type="text" placeholder="ej. inroute2018" onChange={(e) => this.setState({ password: e.target.value })} value={this.state.password} />
                        </Form.Group>
                        <Form.Group>
                                <Form.Label>Confirmar contraseña:</Form.Label>
                                <Form.Control type="text" placeholder="ej. inroute2018" onChange={(e) => this.setState({ password2: e.target.value })} value={this.state.password2} />
                        </Form.Group>
                        <Form.Group>
                                <Form.Label>Apikey:</Form.Label>
                                <Form.Control type="text" placeholder="ej. 3434233dd3d3323r3" onChange={(e) => this.setState({ apikey: e.target.value })} value={this.state.apikey} />
                        </Form.Group>
                        <Button loading={this.state.loading} onClick={()=>this.onClickButton()} floated fluid style={{backgroundColor:config.colors.green, color: 'white'}}>Añadir cuenta</Button>                                
            
                    </div>
                        {this.state.loadingHistory ?   
                <Container style={styles.loaderHistory}>
                    <Loader size='massive' active inline='centered'  >Cargando cuentas...</Loader>
                </Container>
                :
                <div style={ styles.tableContainer}>
                    <div style={styles.headerTable} >
                    <span style={{fontSize:25, color:'white'}} >Cuentas</span>
                    <Button loading={this.state.loading} style={{ backgroundColor: config.colors.green, color: 'white' }}  onClick={() => {this.getAccounts()}} >
                        <img style={{width:12,height:12, marginRight:5}} src={'http://168.235.83.246/images/refresh.png'} />
                        Actualizar
                    </Button>
                </div>
                    <Table responsive striped bordered  hover style={{ boxShadow: '1px 3px 1px #9E9E9E', width: '100%'}}>
                    <thead style={{ backgroundColor: '#E2DDDD' }}>
                        <th>
                            Nombre
                    </th>
                        <th>
                            Cuenta
                    </th>
                        <th>
                            Usuario
                    </th>
                        {/* <th>
                            Contraseña
                    </th> */}
                    <th>
                            Apikey
                    </th>
                    </thead>
                    <tbody style={{ backgroundColor: 'white' }}>
                        {this.renderRows()}
                    </tbody>
                </Table>
                </div>
       
            }
                    {this.renderModal()}
                    </div>
                    )
        }else{
            return (
                <Container style={styles.loader}>
                        <Loader size='large' active inline='centered'>Cargando cuentas...</Loader>
                    </Container>
                    )
                }
            }
        
        }
        
const styles = {
                        loader: {
                        marginTop: 100
                },
    container:{
                        flex: 1,
                    flexDirection: 'row',
                    display: 'flex',
                    height: '92%',
                    backgroundColor: config.colors.lightGray,padding:20
                },
    form:{
                        padding: 20,
                   
                    width: '30%',
                    backgroundColor: 'white'
                },
    table:{
                        flex: 1,
                    flexDirection: 'column',
                    display: 'flex',
                    margin:10,
                    width: '60%',
                    backgroundColor: 'white'
                },
    searchbar:{
                        widthflex: 1,
                    flexDirection: 'row',
                    display: 'flex',
                    height: 60,
                    padding: 10,
                    backgroundColor: config.colors.strongGray,
                    width: '100%'
                },
    label:{
                        flexDirection: 'row',
                    display: 'flex',
                    marginTop: 20
                },
                headerTable: {
                    display: 'flex',
                    width: '100%', 
                    padding: 5, 
                    backgroundColor: '#717188', 
                    flexDirection: 'row', 
                    alignItems: 'center',
                    justifyContent:'space-between' 
                },
                tableContainer: {
                    display: 'flex', 
                    height: '100%', 
                    width: '70%',
                    marginLeft:15,
                    flexDirection:'column'
                },
                searchButton: {
                    backgroundColor: config.colors.blue,
                    color: 'white',
                    marginTop: window.innerHeight>1000? 5:30
                },
                refreshButton: {
                    backgroundColor: config.colors.green,
                    color: 'white',
                    marginLeft:45
                },loaderHistory: {
                    width: '70%',
                    backgroundColor: config.colors.lightGray,
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1,
                    alignSelf:'center',
                },
                
            }
            

        
const mapStateToProps = state => {
    return {
            account: state.auth.account,
            password: state.auth.password,
            username: state.auth.username,
            accounts: state.auth.accounts,
            message: state.lbox.message
             };
}
export default connect(mapStateToProps, 
{
    getAccounts,
    onAccountChanged,
   onUsernameChanged,
   onApiKeychanged,
   onPasswordChanged,
   addAccount,
   updateAccount,
   deleteAccount
})(Pruebas);