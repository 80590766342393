import {
    ON_ACCOUNT_CHANGED,
    ON_USERNAME_CHANGED,
    ON_PASSWORD_CHANGED,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    ON_APIKEY_CHANGED,
    GET_ACCOUNTS_SUCCESS,
    GET_ACCOUNTS_FAIL
} from './types';
import axios from 'axios';
const URL_ROOT ="http://168.235.83.246/robota/";


export const onApiKeychanged = text => dispatch =>{
    dispatch({type: ON_APIKEY_CHANGED, payload: text})
}

export const onAccountChanged = text => dispatch =>{
    dispatch({type: ON_ACCOUNT_CHANGED, payload: text})
}

export const onUsernameChanged = text => dispatch =>{
    dispatch({type: ON_USERNAME_CHANGED, payload: text})
}

export const onPasswordChanged = text => dispatch =>{
    dispatch({type: ON_PASSWORD_CHANGED, payload: text})
}
export const doLogin = (account,username,password) => async dispatch =>{
    try {
        let { data } = await axios.get(URL_ROOT, {
            params:{
                Accion:"iniciarSesion",
                cuenta: account,
                password: password,
                usuario:username
            }
        });
        console.log(data);
        if(data.error){
            dispatch({type: LOGIN_FAIL, payload: data.error})
        }else{
            await sessionStorage.setItem('token', JSON.stringify(data))
            dispatch({type: LOGIN_SUCCESS, payload: data});
        }
    }catch(err){
        dispatch({type: LOGIN_FAIL, payload: 'Ocurrio un problema, Intente de nuevo.'})
    }
}
export const getAccounts = (account, username ,password) => async dispatch => {
    try {
        let { data } = await axios.get(URL_ROOT, {
            params:{
                Accion:"consultarCuentas",
                cuenta: account,
                usuario: username,
                password: password
            }
        })
        if(data.error){
            dispatch({type: GET_ACCOUNTS_FAIL, payload: "Error al consultar cuentas. Comuniquese con el area de TI"});
        }
        else{
            dispatch({type: GET_ACCOUNTS_SUCCESS, payload: data})
        }
    }catch(err){
        dispatch({type: GET_ACCOUNTS_FAIL, payload:"Error al consultar cuentas. Comuniquese con el area de TI"})
    }
}



