import _ from 'lodash';
import React, { Component } from 'react';
import { Loader, Container, Dropdown, Button, Grid } from 'semantic-ui-react';
import { Table, Form, FormGroup, Modal, Navbar } from 'react-bootstrap';
import moment from 'moment'
import config from '../config/index'
import { connect } from 'react-redux';
import {
    getLboxs,
    onUsernameChanged,
    onAccountChanged,
    onPasswordChanged,
    resetLinkMessageQueue,
    getHistory,
    sendDataLbox,
} from '../redux/actions';

class CrudCommands extends Component {
    state = {
        history: [],
        vehicles: [],
        selectedVehicle: null,
        refreshing: false,
        loadingHistory: false,
        cleaningMsgQueue: false,
        command: null,
        sending: false

    }
    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }
    async componentWillMount() {
        let token = await sessionStorage.getItem('token');
        if (!token) {
            alert('Debes de iniciar sesion')
            this.props.history.push('/#')
        }
        else {
            let user = JSON.parse(token)
            this.props.onUsernameChanged(user.username);
            this.props.onAccountChanged(user.account);
            this.props.onPasswordChanged(user.password);
            const { account, username, password, getLboxs } = this.props;
            await getLboxs(account, username, password);

            let aux = [];

            if (this.props.lboxs.length > 0) {

                this.props.lboxs.map(
                    lbox => {
                        aux.push({ text: lbox.objectno, value: lbox });
                    }
                )
            }
            this.setState({ loading: false, vehicles: aux });
        }
    }
    onSelectedLBox(lbox) {
        this.setState({ selectedVehicle: lbox, history: [] })
        this.searchHistory(lbox);

    }
    filterForText(text) {
        if (text === "") {

            this.setState({ commands: this.props.commands })
        }
        const newArray = this.props.commands.filter(
            item => {
                const textData = String(text).toUpperCase();
                const commandName = String(item.nombre).toUpperCase();

                if (commandName.indexOf(textData) !== -1) {
                    return commandName.indexOf(textData) > -1
                }
            }
        );



        this.setState({ commands: newArray, data: text });


    };
    async searchHistory(lbox) {
        const { selectedVehicle, startDate, endDate, searchDateOption } = this.state
        const { account, password, username, } = this.props;

        let obj = lbox;
        if (obj) {
            this.setState({ refreshing: true, history: [] });
            let startDateUtc = ''
            let endDateUtc = ''
            let todayStart = moment().format('YYYY-MM-DD 00:00:00');
            let todayEnd = moment().format('YYYY-MM-DD 23:59:59');
            startDateUtc = moment(todayStart, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
            endDateUtc = moment(todayEnd, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss');
            await this.props.getHistory(account, username, password, obj.objectno, obj.deviceid, startDateUtc, endDateUtc);
            if (lbox) {
                let newSelectedLbox = _.find(this.props.lboxs, { objectno: lbox.objectno });
                this.setState({ selectedVehicle: newSelectedLbox });
            }
            this.setState({ refreshing: false, history: this.props.history })
            if (this.props.message) {
                alert(this.props.message)
            }
        } else {
            alert("No se ha seleccionado un vehículo")
        }






    }
    renderRows() {

        if (this.state.history.length > 0) {
            return this.state.history.map(
                item => {

                    return (
                        <tr>
                            <td>{item.fecha + " " + item.hora}</td>
                            <td>{item.latitud ? <a href={`http://www.google.com/maps/?q=${(item.latitud / 1000000)},${(item.longitud / 1000000)}`} target="_blank" >{(item.latitud / 1000000) + "," + (item.longitud / 1000000)}</a> : "Sin posición"}</td>
                            <td>{item.odometro ? item.odometro : 'Sin odometro'}</td>
                            <td>{item.aux_data}</td>
                        </tr>
                    );
                }
            )
        } else {
            return (
                <tr>
                    <td>No se encontraron mensajes</td>
                </tr>
            );

        }
    }
    async onButtonClick() {
        if (this.state.command === null || this.state.command === "" || this.state.command === undefined) {
            alert("Es necesario ingresar el comando a enviar");
        }
        else {
            this.setState({ sending: true });
            const { account, username, password, sendDataLbox } = this.props;
            const { objectno, deviceid } = this.state.selectedVehicle;
            await sendDataLbox(account, username, password, objectno, deviceid, this.state.command);
            this.setState({ sending: false,command: null });
            alert(this.props.message);
        }


    }
    async clearLinkMessageQueue() {
        this.setState({ cleaningMsgQueue: true });
        const { account, username, password, resetLinkMessageQueue } = this.props;
        await resetLinkMessageQueue(account, username, password, this.state.selectedVehicle.objectno);
        alert(this.props.message);
        this.setState({ cleaningMsgQueue: false });
    }

    render() {
        if (this.state.vehicles.length > 0) {

            let fechaUtc = this.state.selectedVehicle ? moment(this.state.selectedVehicle.fecha, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YYYY HH:mm:ss UTC') : '';
            let timez = this.state.selectedVehicle ? new Date(fechaUtc).toString() : null;
            return (
                <div style={styles.container}>
                    <div style={styles.form}>
                        <h2>Pruebas de instalación.</h2>
                        <Form style={{ width: '100%', marginTop: 20 }} inline>
                            <Form.Label style={{ width: '40%', marginRight: '2%', fontSize: 17 }}>Selecciona un vehículo:</Form.Label>
                            <Dropdown
                                style={{ width: '55%' }}
                                selection
                                search
                                options={this.state.vehicles}
                                onChange={(e, data) => this.onSelectedLBox(data.value)}
                            />

                        </Form>
                        <div style={styles.label}>
                            <h2>Información</h2>
                        </div>
                        <div style={styles.informationContainer}>
                            <div style={styles.infoSubContainer}>
                                <div style={styles.infoRowContainer}>
                                    <span style={styles.infoSubTitle}>Dispositivo:</span>
                                    <span style={{ width: '55%', fontSize: 17 }}>{this.state.selectedVehicle ? this.state.selectedVehicle.objectno : ''}</span>
                                </div>
                                <div style={styles.infoRowContainer}>
                                    <span style={styles.infoSubTitle}>Device id:</span>
                                    <span style={{ width: '55%', fontSize: 15 }}>{this.state.selectedVehicle ? this.state.selectedVehicle.deviceid : ''}</span>
                                </div>
                                <div style={styles.infoRowContainer}>
                                    <span style={styles.infoSubTitle}>Serie LBox:</span>
                                    <span style={{ width: '55%', fontSize: 17 }}>{this.state.selectedVehicle ? this.state.selectedVehicle.serieLBOX : ''}</span>
                                </div>

                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', width: '47%' }}>
                                <div style={styles.infoRowContainer}>
                                    <span style={styles.infoSubTitle}>Estado:</span>
                                    <span style={{ width: '55%', fontSize: 17 }}>{this.state.selectedVehicle ? this.state.selectedVehicle.modo : ''}</span>
                                </div>
                                <div style={styles.infoRowContainer}>
                                    <span style={styles.infoSubTitle}>Reporte:</span>
                                    <span style={{ width: '55%', fontSize: 16 }}>{this.state.selectedVehicle ? moment(timez).format('DD-MM-YYYY HH:mm:ss') : ''}</span>
                                </div>
                            </div>
                        </div>
                        <Form.Group controlId="formBasicEmail" >
                            <Form.Label>Ingresa el comando a enviar:</Form.Label>
                            <Form.Control value={this.state.command} placeholder="Ingresa el comando ej **i" onChange={(e) => this.setState({ command: e.target.value })} />
                        </Form.Group>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignSelf: 'center' }}>
                            <Button disabled={this.state.selectedVehicle ? false : true} fluid loading={this.state.cleaningMsgQueue} onClick={() => this.clearLinkMessageQueue()} style={{ marginTop: 20, backgroundColor: config.colors.blue, color: 'white' }} >Limpiar cola de msj</Button>
                            <Button disabled={this.state.selectedVehicle ? false : true} fluid loading={this.state.sending} onClick={() => this.onButtonClick()} style={{ marginTop: 20, backgroundColor: config.colors.green, color: 'white' }} >Enviar</Button>
                        </div>
                    </div>
                    {this.state.loadingHistory ?
                        <Container style={styles.loaderHistory}>
                            <Loader size='massive' active inline='centered'  >Cargando historial...</Loader>
                        </Container>
                        :
                        <div style={styles.tableContainer}>
                            <div style={styles.headerTable} >
                                <span style={{ fontSize: 25, color: 'white' }} >Historial</span>
                                <Button loading={this.state.refreshing} style={{ backgroundColor: config.colors.green, color: 'white' }} onClick={() => this.searchHistory(this.state.selectedVehicle)} >
                                    <img style={{ width: 12, height: 12, marginRight: 5 }} src={`http://168.235.83.246/images/refresh.png`} />
                                    Actualizar
                    </Button>
                            </div>
                            <Table responsive striped bordered hover style={{ boxShadow: '1px 3px 1px #9E9E9E', width: '100%' }}>
                                <thead style={{ backgroundColor: '#E2DDDD' }}>
                                    <th>
                                        Fecha
                    </th>
                                    <th>
                                        Posición
                    </th>
                                    <th>
                                        Odómetro
                    </th>
                                    <th>
                                        Mensaje recibido
                    </th>
                                </thead>
                                <tbody style={{ backgroundColor: 'white' }}>
                                    {this.renderRows()}
                                </tbody>
                            </Table>
                        </div>

                    }
                </div>

            )
        } else {
            return (
                <Container style={styles.loader}>
                    <Loader size='large' active inline='centered'>Cargando vehículos...</Loader>
                </Container>
            )
        }
    }

}

const styles = {
    loader: {
        marginTop: 100
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        display: 'flex',
        height: '92%',
        backgroundColor: config.colors.lightGray, padding: 20
    },
    form: {
        padding: 20,

        width: '40%',
        backgroundColor: 'white'
    },
    table: {
        flex: 1,
        flexDirection: 'column',
        display: 'flex',
        margin: 10,
        width: '60%',
        backgroundColor: 'white'
    },
    searchbar: {
        widthflex: 1,
        flexDirection: 'row',
        display: 'flex',
        height: 60,
        padding: 10,
        backgroundColor: config.colors.strongGray,
        width: '100%'
    },
    label: {
        flexDirection: 'row',
        display: 'flex',
        marginTop: 20
    },
    headerTable: {
        display: 'flex',
        width: '100%',
        padding: 5,
        backgroundColor: '#717188',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    tableContainer: {
        display: 'flex',
        height: '100%',
        width: '70%',
        marginLeft: 15,
        flexDirection: 'column'
    },
    searchButton: {
        backgroundColor: config.colors.blue,
        color: 'white',
        marginTop: window.innerHeight > 1000 ? 5 : 30
    },
    refreshButton: {
        backgroundColor: config.colors.green,
        color: 'white',
        marginLeft: 45
    }, loaderHistory: {
        width: '70%',
        backgroundColor: config.colors.lightGray,
        display: 'flex',
        justifyContent: 'center',
        flex: 1,
        alignSelf: 'center',
    }, informationContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        padding: 10,
        justifyContent: 'space-between'
    },
    infoSubContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '47%'
    },
    infoRowContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 10
    }, infoSubTitle: {
        width: '40%',
        fontSize: 16,
        color: 'lightgray'
    },
    commandContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 10, flex: 1
    },
    commandSearchbar: {
        width: '100%',
        marginRight: 15,
        height: 38
    },
    commandButtonsContainer: {
        display: 'flex',
        width: '100%',
        flexWrap: 'wrap',
        height: '15%',
        overflow: 'auto',
        justifyContent: 'space-between'
    }

}



const mapStateToProps = state => {
    return {
        account: state.auth.account,
        password: state.auth.password,
        username: state.auth.username,
        lboxs: state.lbox.lboxs,
        history: state.lbox.history,
        message: state.lbox.message,
    };
}
export default connect(mapStateToProps,
    {
        getLboxs,
        onAccountChanged,
        onUsernameChanged,
        onPasswordChanged,
        getHistory,
        sendDataLbox,
        resetLinkMessageQueue,
    })(CrudCommands);